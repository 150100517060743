/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../../Fixes';
import Builder from '../../../Builder';
import '../../client/orders/model';
import '../../connect/v2/resources/address';
import '../../privacyvault/annotations';

const $root = ($protobuf.roots['default']|| ($protobuf.roots['default'] = new $protobuf.Root()));

const jsonData = {
  "squareup": {
    "nested": {
      "omg": {
        "nested": {
          "fulfillment": {
            "nested": {
              "Fulfillment": {
                "fields": {
                  "uid": {
                    "type": "string",
                    "id": 1,
                    "rule": "optional"
                  },
                  "type": {
                    "type": "squareup.omg.fulfillment.FulfillmentType",
                    "id": 2,
                    "rule": "optional"
                  },
                  "state": {
                    "type": "squareup.omg.fulfillment.Fulfillment.State",
                    "id": 3,
                    "rule": "optional"
                  },
                  "lineItemApplication": {
                    "type": "squareup.omg.fulfillment.Fulfillment.FulfillmentLineItemApplication",
                    "id": 8,
                    "rule": "optional",
                    "options": {
                      "default": "ALL"
                    }
                  },
                  "entries": {
                    "type": "squareup.omg.fulfillment.Fulfillment.FulfillmentEntry",
                    "id": 4,
                    "rule": "repeated"
                  },
                  "metadata": {
                    "type": "string",
                    "id": 5,
                    "keyType": "string"
                  },
                  "pickupDetails": {
                    "type": "squareup.omg.fulfillment.FulfillmentPickupDetails",
                    "id": 6,
                    "rule": "optional"
                  },
                  "managedDeliveryDetails": {
                    "type": "squareup.omg.fulfillment.FulfillmentManagedDeliveryDetails",
                    "id": 7,
                    "rule": "optional"
                  },
                  "shipmentDetails": {
                    "type": "squareup.omg.fulfillment.FulfillmentShipmentDetails",
                    "id": 9,
                    "rule": "optional"
                  },
                  "digitalDetails": {
                    "type": "squareup.omg.fulfillment.FulfillmentDigitalDetails",
                    "id": 12,
                    "rule": "optional"
                  },
                  "deliveryDetails": {
                    "type": "squareup.omg.fulfillment.FulfillmentDeliveryDetails",
                    "id": 13,
                    "rule": "optional"
                  },
                  "simpleDetails": {
                    "type": "squareup.omg.fulfillment.FulfillmentSimpleDetails",
                    "id": 17,
                    "rule": "optional"
                  },
                  "dineInDetails": {
                    "type": "squareup.omg.fulfillment.FulfillmentDineInDetails",
                    "id": 20,
                    "rule": "optional"
                  },
                  "inStoreDetails": {
                    "type": "squareup.omg.fulfillment.FulfillmentInStoreDetails",
                    "id": 21,
                    "rule": "optional"
                  },
                  "locationId": {
                    "type": "string",
                    "id": 15,
                    "rule": "optional"
                  },
                  "createdAt": {
                    "type": "string",
                    "id": 16,
                    "rule": "optional"
                  },
                  "version": {
                    "type": "int32",
                    "id": 18,
                    "rule": "optional"
                  },
                  "readyToFulfill": {
                    "type": "bool",
                    "id": 19,
                    "rule": "optional"
                  },
                  "statusTrackingEnabled": {
                    "type": "bool",
                    "id": 22,
                    "rule": "optional"
                  },
                  "wasStatus": {
                    "type": "squareup.omg.fulfillment.Fulfillment.State",
                    "id": 9000,
                    "rule": "optional"
                  },
                  "extFulfillmentClientDetails": {
                    "type": "squareup.client.orders.FulfillmentClientDetails",
                    "id": 10000,
                    "rule": "optional"
                  }
                },
                "nested": {
                  "FulfillmentLineItemApplication": {
                    "values": {
                      "UNKNOWN_APPLICATION": 0,
                      "ALL": 1,
                      "ENTRY_LIST": 2
                    }
                  },
                  "FulfillmentEntry": {
                    "fields": {
                      "uid": {
                        "type": "string",
                        "id": 1,
                        "rule": "optional"
                      },
                      "lineItemUid": {
                        "type": "string",
                        "id": 2,
                        "rule": "optional"
                      },
                      "quantity": {
                        "type": "string",
                        "id": 3,
                        "rule": "optional"
                      },
                      "metadata": {
                        "type": "string",
                        "id": 6,
                        "keyType": "string"
                      }
                    }
                  },
                  "State": {
                    "values": {
                      "FULFILLMENT_STATE_DO_NOT_USE": 0,
                      "PROPOSED": 1,
                      "RESERVED": 2,
                      "PREPARED": 3,
                      "COMPLETED": 4,
                      "CANCELED": 5,
                      "FAILED": 6
                    }
                  }
                }
              },
              "FulfillmentType": {
                "values": {
                  "FULFILLMENT_TYPE_DO_NOT_USE": 0,
                  "CUSTOM": 1,
                  "PICKUP": 2,
                  "MANAGED_DELIVERY": 3,
                  "SHIPMENT": 4,
                  "DIGITAL": 5,
                  "DELIVERY": 6,
                  "SIMPLE": 7,
                  "DINE_IN": 8,
                  "IN_STORE": 9,
                  "BOOKING": 10
                }
              },
              "FulfillmentPickupDetails": {
                "fields": {
                  "recipient": {
                    "type": "squareup.omg.fulfillment.FulfillmentRecipient",
                    "id": 16,
                    "rule": "optional"
                  },
                  "expiresAt": {
                    "type": "string",
                    "id": 1,
                    "rule": "optional"
                  },
                  "autoCompleteDuration": {
                    "type": "string",
                    "id": 19,
                    "rule": "optional"
                  },
                  "scheduleType": {
                    "type": "squareup.omg.fulfillment.FulfillmentPickupDetailsScheduleType",
                    "id": 13,
                    "rule": "optional",
                    "options": {
                      "default": "SCHEDULED"
                    }
                  },
                  "pickupAt": {
                    "type": "string",
                    "id": 5,
                    "rule": "optional"
                  },
                  "pickupWindowDuration": {
                    "type": "string",
                    "id": 20,
                    "rule": "optional"
                  },
                  "prepTimeDuration": {
                    "type": "string",
                    "id": 21,
                    "rule": "optional"
                  },
                  "note": {
                    "type": "string",
                    "id": 7,
                    "rule": "optional"
                  },
                  "placedAt": {
                    "type": "string",
                    "id": 8,
                    "rule": "optional"
                  },
                  "acceptedAt": {
                    "type": "string",
                    "id": 9,
                    "rule": "optional"
                  },
                  "acceptanceAcknowledgedAt": {
                    "type": "string",
                    "id": 18,
                    "rule": "optional"
                  },
                  "rejectedAt": {
                    "type": "string",
                    "id": 10,
                    "rule": "optional"
                  },
                  "readyAt": {
                    "type": "string",
                    "id": 11,
                    "rule": "optional"
                  },
                  "expiredAt": {
                    "type": "string",
                    "id": 12,
                    "rule": "optional"
                  },
                  "pickedUpAt": {
                    "type": "string",
                    "id": 23,
                    "rule": "optional"
                  },
                  "canceledAt": {
                    "type": "string",
                    "id": 24,
                    "rule": "optional"
                  },
                  "cancelReason": {
                    "type": "string",
                    "id": 25,
                    "rule": "optional"
                  },
                  "isCurbsidePickup": {
                    "type": "bool",
                    "id": 26,
                    "rule": "optional"
                  },
                  "curbsidePickupDetails": {
                    "type": "squareup.omg.fulfillment.FulfillmentPickupDetails.CurbsidePickupDetails",
                    "id": 27,
                    "rule": "optional"
                  }
                },
                "nested": {
                  "CurbsidePickupDetails": {
                    "fields": {
                      "curbsideDetails": {
                        "type": "string",
                        "id": 1,
                        "rule": "optional"
                      },
                      "buyerArrivedAt": {
                        "type": "string",
                        "id": 2,
                        "rule": "optional"
                      }
                    }
                  }
                }
              },
              "FulfillmentPickupDetailsScheduleType": {
                "values": {
                  "FULFILLMENT_PICKUP_DETAILS_SCHEDULE_TYPE_DO_NOT_USE": 0,
                  "SCHEDULED": 1,
                  "ASAP": 2
                }
              },
              "FulfillmentRecipient": {
                "fields": {
                  "customerId": {
                    "type": "string",
                    "id": 1,
                    "rule": "optional"
                  },
                  "displayName": {
                    "type": "string",
                    "id": 2,
                    "rule": "optional"
                  },
                  "emailAddress": {
                    "type": "string",
                    "id": 3,
                    "rule": "optional"
                  },
                  "phoneNumber": {
                    "type": "string",
                    "id": 4,
                    "rule": "optional"
                  },
                  "address": {
                    "type": "squareup.connect.v2.resources.Address",
                    "id": 5,
                    "rule": "optional"
                  },
                  "vaultedData": {
                    "type": "squareup.privacyvault.VaultedData",
                    "id": 6,
                    "rule": "optional"
                  },
                  "identifier": {
                    "type": "string",
                    "id": 7,
                    "rule": "optional"
                  }
                }
              },
              "FulfillmentManagedDeliveryDetails": {
                "fields": {
                  "note": {
                    "type": "string",
                    "id": 1,
                    "rule": "optional"
                  },
                  "placedAt": {
                    "type": "string",
                    "id": 2,
                    "rule": "optional"
                  },
                  "pickupAt": {
                    "type": "string",
                    "id": 3,
                    "rule": "optional"
                  },
                  "acceptedAt": {
                    "type": "string",
                    "id": 4,
                    "rule": "optional"
                  },
                  "readyAt": {
                    "type": "string",
                    "id": 5,
                    "rule": "optional"
                  },
                  "pickedUpAt": {
                    "type": "string",
                    "id": 6,
                    "rule": "optional"
                  }
                }
              },
              "FulfillmentShipmentDetails": {
                "fields": {
                  "recipient": {
                    "type": "squareup.omg.fulfillment.FulfillmentRecipient",
                    "id": 1,
                    "rule": "optional"
                  },
                  "carrier": {
                    "type": "string",
                    "id": 2,
                    "rule": "optional"
                  },
                  "shippingNote": {
                    "type": "string",
                    "id": 3,
                    "rule": "optional"
                  },
                  "shippingType": {
                    "type": "string",
                    "id": 4,
                    "rule": "optional"
                  },
                  "trackingNumber": {
                    "type": "string",
                    "id": 5,
                    "rule": "optional"
                  },
                  "trackingUrl": {
                    "type": "string",
                    "id": 15,
                    "rule": "optional"
                  },
                  "placedAt": {
                    "type": "string",
                    "id": 6,
                    "rule": "optional"
                  },
                  "inProgressAt": {
                    "type": "string",
                    "id": 17,
                    "rule": "optional"
                  },
                  "packagedAt": {
                    "type": "string",
                    "id": 7,
                    "rule": "optional"
                  },
                  "expectedShippedAt": {
                    "type": "string",
                    "id": 16,
                    "rule": "optional"
                  },
                  "shippedAt": {
                    "type": "string",
                    "id": 8,
                    "rule": "optional"
                  },
                  "expectedDeliveredAt": {
                    "type": "string",
                    "id": 9,
                    "rule": "optional"
                  },
                  "deliveredAt": {
                    "type": "string",
                    "id": 10,
                    "rule": "optional"
                  },
                  "canceledAt": {
                    "type": "string",
                    "id": 11,
                    "rule": "optional"
                  },
                  "cancelReason": {
                    "type": "string",
                    "id": 12,
                    "rule": "optional"
                  },
                  "failedAt": {
                    "type": "string",
                    "id": 13,
                    "rule": "optional"
                  },
                  "failureReason": {
                    "type": "string",
                    "id": 14,
                    "rule": "optional"
                  }
                }
              },
              "FulfillmentDigitalDetails": {
                "fields": {
                  "recipient": {
                    "type": "squareup.omg.fulfillment.FulfillmentRecipient",
                    "id": 1,
                    "rule": "optional"
                  },
                  "placedAt": {
                    "type": "string",
                    "id": 2,
                    "rule": "optional"
                  },
                  "completedAt": {
                    "type": "string",
                    "id": 3,
                    "rule": "optional"
                  },
                  "canceledAt": {
                    "type": "string",
                    "id": 4,
                    "rule": "optional"
                  },
                  "cancelReason": {
                    "type": "string",
                    "id": 5,
                    "rule": "optional"
                  },
                  "note": {
                    "type": "string",
                    "id": 6,
                    "rule": "optional"
                  }
                }
              },
              "FulfillmentDeliveryDetails": {
                "fields": {
                  "recipient": {
                    "type": "squareup.omg.fulfillment.FulfillmentRecipient",
                    "id": 1,
                    "rule": "optional"
                  },
                  "scheduleType": {
                    "type": "squareup.omg.fulfillment.FulfillmentDeliveryDetails.OrderFulfillmentDeliveryDetailsScheduleType",
                    "id": 2,
                    "rule": "optional",
                    "options": {
                      "default": "SCHEDULED"
                    }
                  },
                  "placedAt": {
                    "type": "string",
                    "id": 3,
                    "rule": "optional"
                  },
                  "deliverAt": {
                    "type": "string",
                    "id": 4,
                    "rule": "optional"
                  },
                  "prepTimeDuration": {
                    "type": "string",
                    "id": 5,
                    "rule": "optional"
                  },
                  "deliveryWindowDuration": {
                    "type": "string",
                    "id": 6,
                    "rule": "optional"
                  },
                  "note": {
                    "type": "string",
                    "id": 7,
                    "rule": "optional"
                  },
                  "completedAt": {
                    "type": "string",
                    "id": 8,
                    "rule": "optional"
                  },
                  "inProgressAt": {
                    "type": "string",
                    "id": 9,
                    "rule": "optional"
                  },
                  "rejectedAt": {
                    "type": "string",
                    "id": 10,
                    "rule": "optional"
                  },
                  "readyAt": {
                    "type": "string",
                    "id": 11,
                    "rule": "optional"
                  },
                  "deliveredAt": {
                    "type": "string",
                    "id": 12,
                    "rule": "optional"
                  },
                  "canceledAt": {
                    "type": "string",
                    "id": 13,
                    "rule": "optional"
                  },
                  "cancelReason": {
                    "type": "string",
                    "id": 14,
                    "rule": "optional"
                  },
                  "isDineIn": {
                    "type": "bool",
                    "id": 15,
                    "rule": "optional"
                  },
                  "dineInDetails": {
                    "type": "squareup.omg.fulfillment.FulfillmentDeliveryDetails.DineInDetails",
                    "id": 16,
                    "rule": "optional"
                  },
                  "courierPickupAt": {
                    "type": "string",
                    "id": 17,
                    "rule": "optional"
                  },
                  "courierPickupWindowDuration": {
                    "type": "string",
                    "id": 18,
                    "rule": "optional"
                  },
                  "isNoContactDelivery": {
                    "type": "bool",
                    "id": 19,
                    "rule": "optional"
                  },
                  "dropoffNotes": {
                    "type": "string",
                    "id": 20,
                    "rule": "optional"
                  },
                  "courierProviderName": {
                    "type": "string",
                    "id": 21,
                    "rule": "optional"
                  },
                  "courierSupportPhoneNumber": {
                    "type": "string",
                    "id": 22,
                    "rule": "optional"
                  },
                  "squareDeliveryId": {
                    "type": "string",
                    "id": 23,
                    "rule": "optional"
                  },
                  "externalDeliveryId": {
                    "type": "string",
                    "id": 24,
                    "rule": "optional"
                  },
                  "managedDelivery": {
                    "type": "bool",
                    "id": 25,
                    "rule": "optional",
                    "options": {
                      "default": false
                    }
                  }
                },
                "nested": {
                  "OrderFulfillmentDeliveryDetailsScheduleType": {
                    "values": {
                      "FULFILLMENT_DELIVERY_DETAILS_SCHEDULE_TYPE_DO_NOT_USE": 0,
                      "SCHEDULED": 1,
                      "ASAP": 2
                    }
                  },
                  "DineInDetails": {
                    "fields": {
                      "tableId": {
                        "type": "string",
                        "id": 1,
                        "rule": "optional"
                      }
                    }
                  }
                }
              },
              "FulfillmentSimpleDetails": {
                "fields": {
                  "completedAt": {
                    "type": "string",
                    "id": 1,
                    "rule": "optional"
                  },
                  "canceledAt": {
                    "type": "string",
                    "id": 2,
                    "rule": "optional"
                  },
                  "cancelReason": {
                    "type": "string",
                    "id": 3,
                    "rule": "optional"
                  },
                  "note": {
                    "type": "string",
                    "id": 4,
                    "rule": "optional"
                  },
                  "placedAt": {
                    "type": "string",
                    "id": 5,
                    "rule": "optional"
                  },
                  "reservedAt": {
                    "type": "string",
                    "id": 6,
                    "rule": "optional"
                  },
                  "preparedAt": {
                    "type": "string",
                    "id": 7,
                    "rule": "optional"
                  },
                  "recipient": {
                    "type": "squareup.omg.fulfillment.FulfillmentRecipient",
                    "id": 8,
                    "rule": "optional"
                  }
                }
              },
              "FulfillmentDineInDetails": {
                "fields": {
                  "note": {
                    "type": "string",
                    "id": 1,
                    "rule": "optional"
                  },
                  "tableId": {
                    "type": "string",
                    "id": 2,
                    "rule": "optional"
                  },
                  "recipient": {
                    "type": "squareup.omg.fulfillment.FulfillmentRecipient",
                    "id": 3,
                    "rule": "optional"
                  }
                }
              },
              "FulfillmentInStoreDetails": {
                "fields": {
                  "note": {
                    "type": "string",
                    "id": 1,
                    "rule": "optional"
                  },
                  "recipient": {
                    "type": "squareup.omg.fulfillment.FulfillmentRecipient",
                    "id": 3,
                    "rule": "optional"
                  },
                  "placedAt": {
                    "type": "string",
                    "id": 4,
                    "rule": "optional"
                  },
                  "completedAt": {
                    "type": "string",
                    "id": 5,
                    "rule": "optional"
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// Do not initialize these protos if they have already been defined
if (!$root.nested?.squareup?.nested?.omg?.nested?.fulfillment?.nested?.Fulfillment && !$root.nested?.squareup?.nested?.omg?.nested?.fulfillment?.nested?.Fulfillment?.nested?.FulfillmentLineItemApplication && !$root.nested?.squareup?.nested?.omg?.nested?.fulfillment?.nested?.Fulfillment?.nested?.FulfillmentEntry && !$root.nested?.squareup?.nested?.omg?.nested?.fulfillment?.nested?.Fulfillment?.nested?.State && !$root.nested?.squareup?.nested?.omg?.nested?.fulfillment?.nested?.FulfillmentType && !$root.nested?.squareup?.nested?.omg?.nested?.fulfillment?.nested?.FulfillmentPickupDetails && !$root.nested?.squareup?.nested?.omg?.nested?.fulfillment?.nested?.FulfillmentPickupDetails?.nested?.CurbsidePickupDetails && !$root.nested?.squareup?.nested?.omg?.nested?.fulfillment?.nested?.FulfillmentPickupDetailsScheduleType && !$root.nested?.squareup?.nested?.omg?.nested?.fulfillment?.nested?.FulfillmentRecipient && !$root.nested?.squareup?.nested?.omg?.nested?.fulfillment?.nested?.FulfillmentManagedDeliveryDetails && !$root.nested?.squareup?.nested?.omg?.nested?.fulfillment?.nested?.FulfillmentShipmentDetails && !$root.nested?.squareup?.nested?.omg?.nested?.fulfillment?.nested?.FulfillmentDigitalDetails && !$root.nested?.squareup?.nested?.omg?.nested?.fulfillment?.nested?.FulfillmentDeliveryDetails && !$root.nested?.squareup?.nested?.omg?.nested?.fulfillment?.nested?.FulfillmentDeliveryDetails?.nested?.OrderFulfillmentDeliveryDetailsScheduleType && !$root.nested?.squareup?.nested?.omg?.nested?.fulfillment?.nested?.FulfillmentDeliveryDetails?.nested?.DineInDetails && !$root.nested?.squareup?.nested?.omg?.nested?.fulfillment?.nested?.FulfillmentSimpleDetails && !$root.nested?.squareup?.nested?.omg?.nested?.fulfillment?.nested?.FulfillmentDineInDetails && !$root.nested?.squareup?.nested?.omg?.nested?.fulfillment?.nested?.FulfillmentInStoreDetails) {
	$root.addJSON(jsonData);
}

/**
 * --
 * @desc Contains details about how to fulfill this order.
 * Orders can only be created with at most one fulfillment using the API.
 * However, orders returned by the Orders API might contain multiple fulfillments because sellers can create multiple fulfillments using Square products such as Square Online.
 * --
 *
 * SOURCE Fulfillment @ squareup/omg/fulfillment/fulfillment.proto at 23:1
 */
export const Fulfillment = $root.lookupType('squareup.omg.fulfillment.Fulfillment');
fixType(Fulfillment);
Builder.createAndAttachToType(Fulfillment);
/**
 * --
 * @desc The `line_item_application` describes what order line items this fulfillment applies
 * to. It can be `ALL` or `ENTRY_LIST` with a supplied list of fulfillment entries.
 * --
 *
 * SOURCE FulfillmentLineItemApplication @ squareup/omg/fulfillment/fulfillment.proto at 208:3
 */
Fulfillment.FulfillmentLineItemApplication = $root.lookupEnum('squareup.omg.fulfillment.Fulfillment.FulfillmentLineItemApplication').values;
/**
 * --
 * @desc Links an order line item to a fulfillment. Each entry must reference
 * a valid `uid` for an order line item in the `line_item_uid` field, as well as a `quantity` to
 * fulfill.
 *
 * SOURCE FulfillmentEntry @ squareup/omg/fulfillment/fulfillment.proto at 227:3
 */
Fulfillment.FulfillmentEntry = $root.lookupType('squareup.omg.fulfillment.Fulfillment.FulfillmentEntry');
fixType(Fulfillment.FulfillmentEntry, {
  lineItemUid: { notEmpty: true },
  quantity: { notEmpty: true },
});
Builder.createAndAttachToType(Fulfillment.FulfillmentEntry);
/**
 * --
 * @desc The current state of this fulfillment.
 * --
 *
 * SOURCE State @ squareup/omg/fulfillment/fulfillment.proto at 292:3
 */
Fulfillment.State = $root.lookupEnum('squareup.omg.fulfillment.Fulfillment.State').values;
/**
 * --
 * @desc The type of fulfillment.
 * --
 *
 * SOURCE FulfillmentType @ squareup/omg/fulfillment/fulfillment.proto at 332:1
 */
export const FulfillmentType = $root.lookupEnum('squareup.omg.fulfillment.FulfillmentType').values;
/**
 * --
 * @desc Contains details necessary to fulfill a pickup order.
 * --
 *
 * SOURCE FulfillmentPickupDetails @ squareup/omg/fulfillment/fulfillment.proto at 397:1
 */
export const FulfillmentPickupDetails = $root.lookupType('squareup.omg.fulfillment.FulfillmentPickupDetails');
fixType(FulfillmentPickupDetails);
Builder.createAndAttachToType(FulfillmentPickupDetails);
/**
 * --
 * @desc Specific details for curbside pickup.
 * --
 *
 * SOURCE CurbsidePickupDetails @ squareup/omg/fulfillment/fulfillment.proto at 562:3
 */
FulfillmentPickupDetails.CurbsidePickupDetails = $root.lookupType('squareup.omg.fulfillment.FulfillmentPickupDetails.CurbsidePickupDetails');
fixType(FulfillmentPickupDetails.CurbsidePickupDetails);
Builder.createAndAttachToType(FulfillmentPickupDetails.CurbsidePickupDetails);
/**
 * --
 * @desc The schedule type of the pickup fulfillment.
 * --
 *
 * SOURCE FulfillmentPickupDetailsScheduleType @ squareup/omg/fulfillment/fulfillment.proto at 602:1
 */
export const FulfillmentPickupDetailsScheduleType = $root.lookupEnum('squareup.omg.fulfillment.FulfillmentPickupDetailsScheduleType').values;
/**
 * --
 * @desc Information about the fulfillment recipient.
 * --
 *
 * SOURCE FulfillmentRecipient @ squareup/omg/fulfillment/fulfillment.proto at 619:1
 */
export const FulfillmentRecipient = $root.lookupType('squareup.omg.fulfillment.FulfillmentRecipient');
fixType(FulfillmentRecipient);
Builder.createAndAttachToType(FulfillmentRecipient);
/**
 * SOURCE FulfillmentManagedDeliveryDetails @ squareup/omg/fulfillment/fulfillment.proto at 700:1
 */
export const FulfillmentManagedDeliveryDetails = $root.lookupType('squareup.omg.fulfillment.FulfillmentManagedDeliveryDetails');
fixType(FulfillmentManagedDeliveryDetails);
Builder.createAndAttachToType(FulfillmentManagedDeliveryDetails);
/**
 * --
 * @desc Contains the details necessary to fulfill a shipment order.
 * --
 *
 * SOURCE FulfillmentShipmentDetails @ squareup/omg/fulfillment/fulfillment.proto at 717:1
 */
export const FulfillmentShipmentDetails = $root.lookupType('squareup.omg.fulfillment.FulfillmentShipmentDetails');
fixType(FulfillmentShipmentDetails);
Builder.createAndAttachToType(FulfillmentShipmentDetails);
/**
 * SOURCE FulfillmentDigitalDetails @ squareup/omg/fulfillment/fulfillment.proto at 856:1
 */
export const FulfillmentDigitalDetails = $root.lookupType('squareup.omg.fulfillment.FulfillmentDigitalDetails');
fixType(FulfillmentDigitalDetails);
Builder.createAndAttachToType(FulfillmentDigitalDetails);
/**
 * --
 * @desc Describes delivery details of an order fulfillment.
 * --
 *
 * SOURCE FulfillmentDeliveryDetails @ squareup/omg/fulfillment/fulfillment.proto at 881:1
 */
export const FulfillmentDeliveryDetails = $root.lookupType('squareup.omg.fulfillment.FulfillmentDeliveryDetails');
fixType(FulfillmentDeliveryDetails);
Builder.createAndAttachToType(FulfillmentDeliveryDetails);
/**
 * --
 * @desc The schedule type of the delivery fulfillment.
 * --
 *
 * SOURCE OrderFulfillmentDeliveryDetailsScheduleType @ squareup/omg/fulfillment/fulfillment.proto at 887:3
 */
FulfillmentDeliveryDetails.OrderFulfillmentDeliveryDetailsScheduleType = $root.lookupEnum('squareup.omg.fulfillment.FulfillmentDeliveryDetails.OrderFulfillmentDeliveryDetailsScheduleType').values;
/**
 * --
 * @desc Specific details for dine-in delivery.
 * --
 *
 * SOURCE DineInDetails @ squareup/omg/fulfillment/fulfillment.proto at 1042:3
 */
FulfillmentDeliveryDetails.DineInDetails = $root.lookupType('squareup.omg.fulfillment.FulfillmentDeliveryDetails.DineInDetails');
fixType(FulfillmentDeliveryDetails.DineInDetails);
Builder.createAndAttachToType(FulfillmentDeliveryDetails.DineInDetails);
/**
 * SOURCE FulfillmentSimpleDetails @ squareup/omg/fulfillment/fulfillment.proto at 1149:1
 */
export const FulfillmentSimpleDetails = $root.lookupType('squareup.omg.fulfillment.FulfillmentSimpleDetails');
fixType(FulfillmentSimpleDetails);
Builder.createAndAttachToType(FulfillmentSimpleDetails);
/**
 * SOURCE FulfillmentDineInDetails @ squareup/omg/fulfillment/fulfillment.proto at 1224:1
 */
export const FulfillmentDineInDetails = $root.lookupType('squareup.omg.fulfillment.FulfillmentDineInDetails');
fixType(FulfillmentDineInDetails);
Builder.createAndAttachToType(FulfillmentDineInDetails);
/**
 * SOURCE FulfillmentInStoreDetails @ squareup/omg/fulfillment/fulfillment.proto at 1251:1
 */
export const FulfillmentInStoreDetails = $root.lookupType('squareup.omg.fulfillment.FulfillmentInStoreDetails');
fixType(FulfillmentInStoreDetails);
Builder.createAndAttachToType(FulfillmentInStoreDetails);
