import React, {
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { observer } from 'mobx-react';
import { createPortal } from 'react-dom';
import { useThrottledCallback } from 'use-debounce';
import Logger from 'src/Logger';
import CloseIcon from 'src/svgs/CloseIcon';
import BackIcon from 'src/svgs/MarketBackIcon';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import { getReactRoot, getShadowRoot } from 'src/utils/shadowDomUtils';
import { MarketAccessory, MarketButton } from 'src/components/Market';
import { Photo } from 'src/MessengerTypes';
import {
  CAROUSEL_PHOTO_CLASS,
  PHOTO_GALLERY_BUTTON_CLASS,
  PHOTO_GALLERY_CAROUSEL_CLASS,
  PHOTO_GALLERY_CAROUSEL_ROW_CLASS,
  PHOTO_GALLERY_CLOSE_BUTTON_CLASS,
  PHOTO_GALLERY_NEXT_BUTTON_CLASS,
  PHOTO_GALLERY_PREV_BUTTON_TEST_ID,
  PHOTO_GALLERY_VIEWER_CLASS,
  PHOTO_GALLERY_VIEWER_PHOTO_CLASS,
  PHOTO_GALLERY_VIEWER_SIDE_CLASS,
} from './constants';
import CarouselPhoto from './CarouselPhoto';
import './PhotosGallery.scss';

// Duration to fade out when closing, should be sync with $photos-gallery-fade-animation-duration
// in PhotosGallery.scss.
const FADE_OUT_DURATION = 200;
// Duration (in ms) between subsequent calls to fetch photo attachments.
const TIME_UNTIL_NEXT_LOAD_ATTACHMENTS = 1000;
// When the scrollable content has less than this many pixels left from the right end,
// the call to load more attachments is triggered.
// This is currently set to the width of roughly 1 photo.
const SCROLL_BUFFER_TO_LOAD_MORE = 100;
// When the selected photo is within this many photos away from the end of the list,
// the call to load more attachments is triggered.
// This is needed because the scrollbar is not always visible.
const PHOTO_BUFFER_TO_LOAD_MORE = 1;
// If there are fewer than this many photos in the gallery, an attempt will be made to load more.
// This is to ensure that the carousel is full when the gallery is loaded.
const MIN_PHOTOS_IN_GALLERY = 50;
// Minimum number of pixels to drag to register it as as swipe
const MIN_SWIPE_DISTANCE = 50;

/**
 * Returns the url before the query parameters.
 *
 * @example
 *  getUrlWithoutQueryString("http://www.google.com?query=...")
 *  would return "http://www.google.com"
 * @param {string} url The url that needs to be truncated.
 * @returns The url before the query parameters.
 */
const getUrlWithoutQueryString = (url: string): string => url.split('?')[0];

/**
 * Determines if the two urls share a common base url.
 *
 * @example
 *  doUrlsWithoutQueryStringsMatch("http://www.google.com?query=...", "http://www.google.com?page=2")
 *  would return true because both urls are the same up until the query parameters.
 * @param {string} firstUrl Url to compare against
 * @param {string} secondUrl Url to compare against
 * @returns boolean, indicating whether the two urls share a common base url
 */
const doUrlsWithoutQueryStringsMatch = (
  firstUrl?: string,
  secondUrl?: string,
): boolean => {
  if (firstUrl === secondUrl) return true;
  if (!firstUrl || !secondUrl) return false;

  return (
    getUrlWithoutQueryString(firstUrl) === getUrlWithoutQueryString(secondUrl)
  );
};

/**
 * A photo viewing gallery that allows the user to view and traverse
 * photos of a conversation in full resolution. On mount it will check if the
 * the attachment tokens has expired, and refresh with GetAttachments if needed.
 *
 * The component has 2 parts:
 * viewer - shows the full resolution image of a selected attachment
 * carousel - shows thumbnails of all the images of the conversation
 *
 * @example
 * Basic usage:
 * <PhotosGallery />
 * @author klim, teresalin
 */
const PhotosGallery = observer((): ReactElement => {
  // ------------------------------------- State & Context ------------------------------------- //
  // Read from context
  const { transcriptView, modal, user } = useMessengerControllerContext();
  const { transcript, selectedPhoto, setSelectedPhoto } = transcriptView;
  const { photos, loadPhotoAttachments, loadPhotoAttachmentsStatus, id } =
    transcript;
  const { closeModal } = modal;

  // Set up state
  const [container] = useState(() => document.createElement('div'));
  const [initialLoadMore, setInitialLoadMore] = useState(true);
  const [hasAnimationEnded, setHasAnimationEnded] = useState(false);
  const carouselRef = useRef<HTMLDivElement>(null);

  // ---------------------------------------- Callbacks ---------------------------------------- //
  /**
   * Match on url and attachment id because local utterance photos that
   * are pending send will not have an attachment id yet.
   * If a local photo is selected and the "hash" property exists, then we need
   * to match on the hash because local photos get removed from `photos` when
   * the server returns the photo back with an attachment id. The server-returned
   * photo will have a different url, so we must match on the hash.
   *
   * Url matching should exclude token query param because that value may
   * change while the user is in the Photo Gallery, due to subsequent
   * calls to GetTranscriptWithUtterances (for fetching latest messages).
   *
   * @param {Photo} photo Photo that is being compared with the selected photo
   * @returns whether the url of the photo in question matches the url of the selected photo
   */
  const isSelectedPhoto = (photo: Photo): boolean => {
    if (!selectedPhoto) return false;

    if (selectedPhoto.isLocal && selectedPhoto.hash) {
      return photo.hash === selectedPhoto.hash;
    }

    return (
      doUrlsWithoutQueryStringsMatch(selectedPhoto?.url, photo.url) &&
      photo.attachmentId === selectedPhoto?.attachmentId
    );
  };

  let indexOfSelectedPhoto = photos.findIndex(isSelectedPhoto);

  if (indexOfSelectedPhoto === -1) {
    // Fallback to first photo and log to Sentry
    indexOfSelectedPhoto = 0;
    Logger.logWithSentry(
      'PhotosGallery - cannot find selected photo',
      'error',
      {
        transcriptId: id,
        merchantToken: user.merchantToken,
        photos,
        selectedPhoto,
      },
    );
  }

  /**
   * Select the previous photo, if possible.
   *
   * This should be wrapped in a useCallback, because it is used in the
   * dependency list of handleKeyboardEvent(). Otherwise, this function will
   * be recreated upon every re-render, which could trigger unnecessary
   * re-registering of handleKeyboardEvent() with the DOM.
   */
  const prevPhoto = useCallback((): void => {
    const prev = photos[Math.max(indexOfSelectedPhoto - 1, 0)];
    setSelectedPhoto(prev);
  }, [photos, indexOfSelectedPhoto, setSelectedPhoto]);

  /**
   * Select the next photo, if possible.
   *
   * This should be wrapped in a useCallback, because it is used in the
   * dependency list of handleKeyboardEvent(). Otherwise, this function will
   * be recreated upon every re-render, which could trigger unnecessary
   * re-registering of handleKeyboardEvent() with the DOM.
   */
  const nextPhoto = useCallback((): void => {
    const next = photos[Math.min(indexOfSelectedPhoto + 1, photos.length - 1)];
    setSelectedPhoto(next);
  }, [photos, indexOfSelectedPhoto, setSelectedPhoto]);

  /**
   * Wrapper to close the gallery after fading out.
   *
   * This should be wrapped in a useCallback, because it is used in the
   * dependency list of handleKeyboardEvent(). Otherwise, this function will
   * be recreated upon every re-render, which could trigger unnecessary
   * re-registering of handleKeyboardEvent() with the DOM.
   */
  const close = useCallback((): void => {
    container.className = 'PhotosGallery PhotosGallery__close';
    setTimeout(() => {
      closeModal();
    }, FADE_OUT_DURATION);
    // TODO (#5429): re-enable eslint rule in the next line, or remove this TODO
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [container.className, closeModal]);

  /**
   * Event handler for keyboard.
   *
   * This should be wrapped in a useCallback, because it is used in the
   * dependency list of a useEffect() when registering the 'keydown' event listener.
   * Otherwise, this function will be recreated upon every re-render, which could
   * trigger unnecessary re-registering of handleKeyboardEvent() with the DOM.
   */
  const handleKeyboardEvent = useCallback(
    (event: KeyboardEvent): void => {
      if (event.key === 'ArrowRight') {
        nextPhoto();
      } else if (event.key === 'ArrowLeft') {
        prevPhoto();
      } else if (event.key === 'Escape') {
        close();
      }
      event.preventDefault();
      event.stopPropagation();
    },
    [nextPhoto, prevPhoto, close],
  );

  /**
   * Loads more photo attachments.
   *
   * This may be called multiple times, due to continuous scrolling.
   * The throttling ensures that there is a delay between simultaneous calls.
   *
   * @param {number} pageSize
   * (Optional) Number of additional photos to fetch. Defaults to 10 if not specified.
   */
  const throttledLoadPhotoAttachments = useThrottledCallback(
    (pageSize?: number) => {
      loadPhotoAttachments(pageSize);
    },
    TIME_UNTIL_NEXT_LOAD_ATTACHMENTS,
  );

  //

  /**
   * Event handler for scrolling.
   * Loads more photos when the scrollbar almost reaches the right side.
   *
   * This should be wrapped in a useCallback, because it is used in the
   * dependency list of a useEffect() when registering the 'scroll' event listener.
   * Otherwise, this function will be recreated upon every re-render, which could
   * trigger unnecessary re-registering of handleScroll() with the DOM.
   */
  const handleScroll = useCallback((): void => {
    const container = carouselRef.current;
    if (!container) return;

    const spaceFromRight =
      container.scrollWidth - container.scrollLeft - container.offsetWidth;

    if (spaceFromRight < SCROLL_BUFFER_TO_LOAD_MORE) {
      throttledLoadPhotoAttachments();
    }
  }, [throttledLoadPhotoAttachments]);

  // --------------------------------------- Swipe logic ---------------------------------------- //

  // Using useRef here instead of useState to prevent re-rendering
  const touchStartX = useRef(0);
  const touchEndX = useRef(0);

  const onTouchStart = useCallback(
    (e) => {
      touchStartX.current = e.touches[0].clientX;
    },
    [touchStartX],
  );

  const onTouchMove = useCallback(
    (e) => {
      touchEndX.current = e.touches[0].clientX;
    },
    [touchEndX],
  );

  const onTouchEnd = useCallback(() => {
    const distance = touchEndX.current - touchStartX.current;
    if (Math.abs(distance) > MIN_SWIPE_DISTANCE) {
      if (distance < 0) {
        // Swiped left
        nextPhoto();
      } else {
        // Swiped right
        prevPhoto();
      }
    }
    touchStartX.current = 0;
    touchEndX.current = 0;
  }, [nextPhoto, prevPhoto]);

  // ----------------------------------------- Effects ----------------------------------------- //

  // This useEffect is meant to only run after the initial render (to append the
  // modal to the React root), and after the component unmounts (to remove the
  // modal from the React root).
  // Only effects that should be run once should be included in this useEffect()
  useEffect(() => {
    container.className = 'PhotosGallery';
    container.dataset.testid = 'PhotosGallery'; /* set data-testid attribute */
    const rootElement = getReactRoot() ?? document.body;
    rootElement.appendChild(container);

    // Cleanup on unmount
    return () => {
      rootElement.removeChild(container);
      setSelectedPhoto(undefined);
    };
    // TODO (#5429): re-enable eslint rule in the next line, or remove this TODO
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Set selected photo to first photo if it's undefined
    if (!selectedPhoto) {
      setSelectedPhoto(photos[0]);
    }
  }, [selectedPhoto, setSelectedPhoto, photos]);

  // Register event listeners
  useEffect(() => {
    document.addEventListener('keydown', handleKeyboardEvent);

    // Cleanup on unmount
    return () => {
      document.removeEventListener('keydown', handleKeyboardEvent);
    };
  }, [handleKeyboardEvent]);

  // Due to the way React Portals are inserted into the DOM, the carousel thumbnails
  // listen to this event in order to know when to create an IntersectionObserver.
  // See <CarouselPhoto /> for more details.
  useEffect(() => {
    const handleAnimationEnd = (): void => {
      setHasAnimationEnded(true);
    };
    container.addEventListener('animationend', handleAnimationEnd);

    // Cleanup on unmount
    return () => {
      container.removeEventListener('animationend', handleAnimationEnd);
    };
    // TODO (#5429): re-enable eslint rule in the next line, or remove this TODO
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let carouselRefNode: HTMLDivElement;

    if (carouselRef.current) {
      carouselRef.current.addEventListener('scroll', handleScroll, {
        passive: true,
      });
      carouselRefNode = carouselRef.current;
    }

    // Cleanup on unmount
    return () => {
      if (carouselRefNode) {
        carouselRefNode.removeEventListener('scroll', handleScroll);
      }
    };
  }, [handleScroll]);

  // If there's no scrollbar, we should still load more photos if we get close
  // to the right side. This uses the index to check if we're close.
  useEffect(() => {
    if (indexOfSelectedPhoto >= photos.length - PHOTO_BUFFER_TO_LOAD_MORE) {
      throttledLoadPhotoAttachments();
    }
  }, [indexOfSelectedPhoto, photos.length, throttledLoadPhotoAttachments]);

  // If there are a small number of photos in the gallery, 1 and only 1 attempt
  // will be made to load more attachments.
  useEffect(() => {
    if (initialLoadMore && photos.length < MIN_PHOTOS_IN_GALLERY) {
      throttledLoadPhotoAttachments(MIN_PHOTOS_IN_GALLERY - photos.length);
      setInitialLoadMore(false);
    }
  }, [initialLoadMore, photos.length, throttledLoadPhotoAttachments]);

  // Scroll thumbnail to the center of carousel if possible
  useEffect(() => {
    const thumbnail = getShadowRoot()?.getElementById(
      `${CAROUSEL_PHOTO_CLASS}${indexOfSelectedPhoto}`,
    );
    if (thumbnail) {
      thumbnail.scrollIntoView({
        inline: 'center',
      });
    }
  }, [indexOfSelectedPhoto]);

  // ----------------------------------------- Render  ----------------------------------------- //
  const photoInView = photos[indexOfSelectedPhoto];

  const thumbnails = photos.map((photo, index) => (
    <CarouselPhoto
      photo={photo}
      uniqueKey={`${index}`}
      isSelected={index === indexOfSelectedPhoto}
      onClick={() => setSelectedPhoto(photo)}
      containerRef={carouselRef}
      key={`PhotosGallery__CarouselPhoto-${index}`}
      isParentLoaded={hasAnimationEnded}
    />
  ));

  const content = (
    <>
      <div
        className={PHOTO_GALLERY_VIEWER_CLASS}
        data-testid={PHOTO_GALLERY_VIEWER_CLASS}
        onClick={() => close()}
      >
        <MarketButton
          className={`${PHOTO_GALLERY_BUTTON_CLASS} ${PHOTO_GALLERY_CLOSE_BUTTON_CLASS}`}
          rank="tertiary"
          data-testid={PHOTO_GALLERY_CLOSE_BUTTON_CLASS}
          onClick={() => close()}
        >
          <MarketAccessory slot="icon" size="icon">
            <CloseIcon />
          </MarketAccessory>
        </MarketButton>

        <div className={PHOTO_GALLERY_VIEWER_SIDE_CLASS}>
          <MarketButton
            className={PHOTO_GALLERY_BUTTON_CLASS}
            data-testid={PHOTO_GALLERY_PREV_BUTTON_TEST_ID}
            onClick={(e) => {
              prevPhoto();
              e.stopPropagation();
            }}
            disabled={indexOfSelectedPhoto === 0 || undefined}
          >
            <MarketAccessory slot="icon" size="icon">
              <BackIcon />
            </MarketAccessory>
          </MarketButton>
        </div>

        <div
          className="PhotosGallery__viewer__center"
          data-testid="PhotosGallery__viewer__center"
          onTouchStart={onTouchStart}
          onTouchEnd={onTouchEnd}
          onTouchMove={onTouchMove}
        >
          <img
            className={PHOTO_GALLERY_VIEWER_PHOTO_CLASS}
            data-testid={PHOTO_GALLERY_VIEWER_PHOTO_CLASS}
            src={photoInView.url}
            key={photoInView.url}
            onClick={(e) => e.stopPropagation()}
          />
        </div>

        <div className={PHOTO_GALLERY_VIEWER_SIDE_CLASS}>
          <MarketButton
            className={`${PHOTO_GALLERY_BUTTON_CLASS} ${PHOTO_GALLERY_NEXT_BUTTON_CLASS}`}
            data-testid={PHOTO_GALLERY_NEXT_BUTTON_CLASS}
            onClick={(e) => {
              nextPhoto();
              e.stopPropagation();
            }}
            disabled={
              !(
                indexOfSelectedPhoto < photos.length - 1 ||
                loadPhotoAttachmentsStatus === 'LOADING'
              ) || undefined
            }
          >
            <MarketAccessory slot="icon" size="icon">
              <BackIcon />
            </MarketAccessory>
          </MarketButton>
        </div>
      </div>
      <div className={PHOTO_GALLERY_CAROUSEL_CLASS}>
        <div className={PHOTO_GALLERY_CAROUSEL_ROW_CLASS} ref={carouselRef}>
          {thumbnails}
        </div>
      </div>
    </>
  );

  return createPortal(content, container);
});

export default PhotosGallery;
