import React, { ReactElement, useEffect, useRef } from 'react';
import { observer } from 'mobx-react';
import { SaaSProvider } from '@squareup/saas-shared-ui';
import MessengerController from 'src/MessengerController';
import MessengerControllerContext from 'src/context/MessengerControllerContext';
import { Currency } from 'src/gen/squareup/connect/v2/common/money';
import { getOrigin } from 'src/utils/url';
import FullPageApp from './FullPageApp';
import { getReactRoot } from './utils/shadowDomUtils';

type FullPageAppContainerProps = {
  messenger: MessengerController;
};

/**
 * The React component root for the Messages Full Page Application.
 *
 * @param {FullPageAppContainerProps} props
 * @param {MessengerController} props.messenger
 */
const FullPageAppContainer = observer(
  ({ messenger }: FullPageAppContainerProps): ReactElement => {
    const saasPortalContainerRef = useRef(document.createElement('div'));

    // Adds portal container for Saas modals, which would otherwise render
    // outside the Messages shadow root, which would cause issues with styling
    useEffect(() => {
      const saasPortalContainer = saasPortalContainerRef.current;

      saasPortalContainer.className = 'SaasPortalInbox';
      const rootElement = getReactRoot() ?? document.body;
      rootElement.appendChild(saasPortalContainer);
      return () => {
        rootElement.removeChild(saasPortalContainer);
      };
    }, []);

    return (
      <MessengerControllerContext.Provider value={messenger}>
        <SaaSProvider
          merchantToken={undefined}
          currencyCode={
            (messenger.user.currencyCode as keyof typeof Currency) || 'USD'
          }
          countryCode={messenger.user.countryCode}
          locale={messenger.user.locale}
          name={messenger.user.businessName}
          // Base URL ensures that Saas requests on Inbox route to api.squareup.com instead of inbox.squareup.com
          // as the routes do not exist there. Not required for the blade as it is not used on Inbox.
          baseUrl={
            process.env.NODE_ENV !== 'development' ? getOrigin() : undefined
          }
          customModalPortalContainer={saasPortalContainerRef.current}
        >
          <FullPageApp />
        </SaaSProvider>
      </MessengerControllerContext.Provider>
    );
  },
);

export default FullPageAppContainer;
