import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import { MarketButton, MarketEmptyState } from 'src/components/Market';
import { onKeyDownEnter } from 'src/utils/keyboardUtils';
import './ClaimYourNumberEmptyState.scss';
import ChatBubblesIcon from 'src/svgs/ChatBubblesIcon';

/**
 * An empty state to show when a Square One merchant is unable to proceed without
 * setting up the dedicated number via TFV. This will be shown when there are no
 * transcripts.
 *
 * @author klim
 */
const ClaimYourNumberEmptyState = observer((): ReactElement => {
  const { navigation } = useMessengerControllerContext();
  const { t } = useTranslation();

  const onClick = (): void => {
    navigation.openStartVerificationView();
  };
  return (
    <MarketEmptyState
      className="ClaimYourNumberEmptyState"
      data-testid="ClaimYourNumberEmptyState"
    >
      <h3 className="heading-20" slot="primary-text">
        <div className="ClaimYourNumberEmptyState__icon">
          <ChatBubblesIcon />
        </div>
        {t('ClaimYourNumber.title')}
      </h3>
      <p className="paragraph-30" slot="secondary-text">
        {t('ClaimYourNumber.description')}
      </p>
      <MarketButton
        rank="primary"
        onClick={onClick}
        onKeyDown={(e) => onKeyDownEnter(e, onClick)}
        slot="actions"
      >
        {t('ClaimYourNumber.button_label')}
      </MarketButton>
    </MarketEmptyState>
  );
});

export default ClaimYourNumberEmptyState;
