/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../Fixes';
import Builder from '../../Builder';

const $root = ($protobuf.roots['default']|| ($protobuf.roots['default'] = new $protobuf.Root()));

const jsonData = {
  "squareup": {
    "nested": {
      "bank_accounts": {
        "nested": {
          "BankAccountType": {
            "values": {
              "UNKNOWN": 0,
              "CHECKING": 1,
              "SAVINGS": 2,
              "INVESTMENT": 3,
              "OTHER": 4,
              "BUSINESS_CHECKING": 5,
              "LOAN": 6,
              "LEDGER": 7
            }
          },
          "SquareBankAccount": {
            "values": {
              "CHASE_PLATFORM": 1,
              "CHASE_CAPITAL": 2,
              "CHASE_COMMERCIAL_ENTITY": 3,
              "CHASE_POLITICAL": 4,
              "CHASE_GIFT_CARDS_FBO": 5,
              "WELLS_PLATFORM": 6,
              "WELLS_CASH": 7,
              "WELLS_PAYROLL_EMPLOYEES": 8,
              "WELLS_PAYROLL_TAX": 9,
              "CHASE_SUBSCRIPTION": 10,
              "CHASE_CAPITAL_TRUST": 11,
              "WELLS_CASH_SETTLEMENTS": 12,
              "CELTIC_PLATFORM": 13,
              "WELLS_CASH_DEBITS": 14,
              "GBP_FPS_BARCLAYS_PLATFORM": 15,
              "AU_CHASE_PLATFORM": 16,
              "CA_CHASE_PLATFORM": 17,
              "FR_BARCLAYS_PLATFORM": 18,
              "JP_MIZUHO_PLATFORM": 19,
              "JP_SMBC_PLATFORM_DAILY": 20,
              "JP_SMBC_PLATFORM_WEEKLY": 21,
              "GBP_BARCLAYS_SAFEGUARD": 22,
              "WELLS_PAYROLL_OPERATING": 23,
              "TEST_PLATFORM": 24,
              "GBP_BARCLAYS_CASH": 25,
              "CA_CHASE_PAYROLL": 26,
              "WELLS_CAVIAR": 27,
              "WELLS_COMMERCIAL_ENTITY": 28,
              "CA_CHASE_COMMERCIAL_ENTITY": 29,
              "WELLS_TEST_PLATFORM": 30,
              "WELLS_CASH_BOOST": 31,
              "WELLS_BIZBANK_REWARDS": 32,
              "PR_CHASE_PLATFORM": 33,
              "VI_CHASE_PLATFORM": 34,
              "GBP_FPS_FORM3_STAGING": 35,
              "WELLS_MARKETPLACE_MTL": 36,
              "WELLS_CASH_ATM": 37,
              "CELTIC_FBO_CAPITAL": 38,
              "GBP_FPS_FORM3_ID_PLATFORM": 39,
              "WELLS_BIZBANK_PAYIN": 40,
              "AU_CHASE_CAPITAL": 41,
              "CHASE_RTP": 42,
              "CHASE_RTP_STAGING": 43,
              "WELLS_CASH_CHECK": 44,
              "CELTIC_CAPITAL_PPP": 45,
              "WELLS_CASH_LENDING": 46,
              "GBP_FPS_FORM3_BOE_STAGING": 47,
              "GBP_FPS_FORM3_BOE": 48,
              "SFS_PLATFORM": 49,
              "SFS_MPO_REPAYMENT": 50,
              "SFS_LOAN_SALES": 51,
              "CHASE_SFS_LOCKBOX": 52,
              "CHASE_SFS_OPERATING": 53,
              "CHASE_SFS_TRUST": 54,
              "EUR_BARCLAYS_PLATFORM": 55,
              "WELLS_CASH_PWC_FBO": 56,
              "WELLS_CASH_PWC_MARKETING": 57,
              "WELLS_CASH_PWC_REVENUE": 58,
              "WELLS_CASH_INSTANT_PAYCHECK": 59,
              "WELLS_ACH_ACCEPTANCE": 60,
              "CA_CHASE_CAPITAL": 61,
              "EUR_BARCLAYS_CASH": 62,
              "WELLS_CASH_PAPER_MONEY_DEPOSIT": 63,
              "WELLS_CASH_WIRE_INFLOW": 64,
              "AU_CUSCAL_NPP": 65,
              "GBP_BARCLAYS_CAPITAL": 66,
              "WELLS_CASH_BILL_PAY": 67,
              "CA_CHASE_CASH": 68,
              "CHASE_CAPITAL_AFTERPAY_LOANS": 69,
              "WELLS_DIGITAL_CHECKS": 70,
              "WELLS_CASH_INTEREST": 71,
              "JP_SMBC_CAPITAL": 72,
              "WELLS_ORDERS_PREFUNDING": 73,
              "WELLS_BILL_PAY": 74,
              "WELLS_AFTERPAY_PAYOUT": 75,
              "WELLS_CASH_ROA": 76,
              "WELLS_PLATFORM_EXPERIMENT": 77,
              "CA_CHASE_FOLDERS": 78,
              "WELLS_CASH_OVERDRAFT_FBO": 79,
              "WELLS_CASH_ITEM": 80,
              "WELLS_CASH_LENDING_ITEM": 81,
              "SFS_BRW_SALES": 82,
              "SFS_BRW_REPMT_SFS_OWNED": 83,
              "SFS_BRW_REPMT_SQC_OWNED": 84,
              "SFS_BRW_REFUNDS_SFS_OWNED": 85,
              "SFS_BRW_REFUNDS_SQC_OWNED": 86,
              "SFS_BRW_SERVICING_FEES": 87,
              "SFS_BRW_BLOCK_FBO": 88,
              "AU_CHASE_CAPITAL_TRUST": 89
            }
          }
        }
      }
    }
  }
}

// Do not initialize these protos if they have already been defined
if (!$root.nested?.squareup?.nested?.bank_accounts?.nested?.BankAccountType && !$root.nested?.squareup?.nested?.bank_accounts?.nested?.SquareBankAccount) {
	$root.addJSON(jsonData);
}

/**
 * Types of bank accounts known at Square.
 *
 * SOURCE BankAccountType @ squareup/bank-accounts/bank_accounts.proto at 18:1
 */
export const BankAccountType = $root.lookupEnum('squareup.bank_accounts.BankAccountType').values;
/**
 * Square bank accounts
 *
 * SOURCE SquareBankAccount @ squareup/bank-accounts/bank_accounts.proto at 64:1
 */
export const SquareBankAccount = $root.lookupEnum('squareup.bank_accounts.SquareBankAccount').values;
