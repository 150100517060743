/**
 * The React app that Conversations embeds into Dashboard, Payroll,
 * and Appointments. The main functionality is wrapped in a component
 * called SideDrawer, patterned after the Dashboard team's Notifications
 * component.
 */
import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import MessengerBlade from './components/MessengerBlade/MessengerBlade';
import AppContent from './AppContent';
import AppOverlays from './AppOverlays';
import './App.scss';
import { useMessengerControllerContext } from './context/MessengerControllerContext';
import LoadingIndicator from './components/LoadingIndicator/LoadingIndicator';
import SubscriptionErrorScreen from './components/SubscriptionErrorScreen/SubscriptionErrorScreen';
import MarketCloseIcon from './svgs/MarketCloseIcon';
import { useTranslation } from 'react-i18next';
import { MarketButton, MarketHeader } from './components/Market';
import useSaasFeatureStatus from './hooks/useSaasFeatureStatus';

/**
 * Components that make up the Messages Blade.
 */
const App = observer((): ReactElement => {
  const { t } = useTranslation();
  const { subscription, navigation } = useMessengerControllerContext();

  useSaasFeatureStatus();

  /**
   * Note: Loading indicator is not meant to be shown on silent Pusher updates, or Saas refetches.
   */
  const isLoadingOrRetrying =
    subscription.status === 'LOADING' || subscription.isSaasLoading;
  const isError = subscription.status === 'ERROR' || subscription.isSaasError;

  if (isLoadingOrRetrying || isError) {
    return (
      <MessengerBlade>
        <MarketHeader>
          <MarketButton
            slot="navigation"
            aria-label={t('common.close')}
            onClick={navigation.primary.navigateBack}
          >
            <MarketCloseIcon slot="icon" />
          </MarketButton>
          <h2>{t('common.messenger')}</h2>
        </MarketHeader>
        {isLoadingOrRetrying ? (
          <LoadingIndicator />
        ) : (
          <SubscriptionErrorScreen />
        )}
      </MessengerBlade>
    );
  }

  return (
    <>
      <MessengerBlade>
        <AppContent />
      </MessengerBlade>
      <AppOverlays />
    </>
  );
});

export default App;
