import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import {
  MessengerModalFullContent,
  MessengerModalFullHeader,
} from 'src/components/MessengerModalFull';
import { MarketButton, MarketField } from 'src/components/Market';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import { onKeyDownEnter } from 'src/utils/keyboardUtils';
import useIsMobile from 'src/hooks/useIsMobile';
import usePreventAccessIfProhibited from 'src/hooks/usePreventAccessIfProhibited';
import UnitSelector from 'src/pages/MessagesPlusStartVerificationPage/components/UnitSelector/UnitSelector';
import './MessagesPlusStartVerificationPage.scss';

/**
 * A full screen page to describe the TFN verification process. If the merchant
 * is a multi-unit merchant, show a location selector to choose which units to subscribe to M+.
 */
const MessagesPlusStartVerificationPage = observer((): ReactElement => {
  const { subscription, navigation, event } = useMessengerControllerContext();
  const { t } = useTranslation();
  const [selectedUnits, setSelectedUnits] = useState<string[]>([]);
  const isMobile = useIsMobile();
  const [hasError, setHasError] = useState(false);

  usePreventAccessIfProhibited();

  useEffect(() => {
    // If only 1 unit can be verified, redirect to UnitVerificationForm
    navigation.openStartVerificationView({ clearNavigation: true });
  }, [navigation]);

  useEffect(() => {
    event.track('View Choose the Locations');
  }, [event]);

  const navigateBack = (): void => {
    if (subscription.isEligibleForSquareOne) {
      navigation.closeSheet();
    } else {
      if (navigation.sheet.canNavigateBack) {
        navigation.sheet.navigateBack();
        return;
      }
      navigation.sheet.navigateTo('MESSAGES_PLUS_PRICING');
    }
  };

  const onClickNext = (): void => {
    if (selectedUnits.length === 0) {
      setHasError(true);
      return;
    }

    event.track('Click Choose the Locations', {
      unit_tokens: selectedUnits,
      action_type_name: 'next',
    });

    navigation.openUnitVerificationView(selectedUnits);
  };

  // There is a bug with MarketSelect that de-renders the content if there is a prop change.
  // This onChange method has to be memoized to prevent UnitSelector from re-rendering
  // when this component re-renders.
  const onChange = useCallback(
    (units: string[]) => {
      setSelectedUnits(units);
      setHasError(false);
    },
    [setSelectedUnits, setHasError],
  );

  return (
    <>
      <MessengerModalFullHeader
        navigationVariant={
          subscription.isEligibleForSquareOne ? 'CLOSE' : 'BACK'
        }
        onNavigateClick={navigateBack}
        primaryButton={
          <MarketButton
            rank="primary"
            onClick={onClickNext}
            onKeyDown={(e) => onKeyDownEnter(e, onClickNext)}
          >
            {t('common.next')}
          </MarketButton>
        }
      />
      <MessengerModalFullContent status={null}>
        <div className="MessagesPlusStartVerificationPage__container">
          <div className="MessagesPlusStartVerificationPage__content MessagesPlusStartVerificationPage__text-container">
            <h2 className="MessagesPlusStartVerificationPage__title">
              {t('MessagesPlusStartVerificationPage.title')}
            </h2>
            <p className="paragraph-30">
              {subscription.isEligibleForSquareOne
                ? t('MessagesPlusStartVerificationPage.description_sqone')
                : t('MessagesPlusStartVerificationPage.description')}
            </p>
            {subscription.unitsAvailableForTFNFlow.length > 1 && (
              <MarketField invalid={hasError || undefined}>
                <UnitSelector onChange={onChange} />
                {hasError && (
                  <small slot="error">
                    {t('MessagesPlusStartVerificationPage.error')}
                  </small>
                )}
              </MarketField>
            )}
          </div>
          {!isMobile && (
            <div className="MessagesPlusStartVerificationPage__content">
              <img
                src="https://conversations-production-f.squarecdn.com/resources/messages-plus/text-us.png"
                alt={
                  subscription.isEligibleForSquareOne
                    ? t(
                        'MessagesPlusStartVerificationPage.image_alt_text_sqone',
                      )
                    : t('MessagesPlusStartVerificationPage.image_alt_text')
                }
              />
            </div>
          )}
        </div>
      </MessengerModalFullContent>
    </>
  );
});

export default MessagesPlusStartVerificationPage;
