/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../Fixes';
import Builder from '../../Builder';

const $root = ($protobuf.roots['default']|| ($protobuf.roots['default'] = new $protobuf.Root()));

const jsonData = {
  "squareup": {
    "nested": {
      "items": {
        "nested": {
          "merchant": {
            "nested": {
              "CatalogObjectType": {
                "values": {
                  "DO_NOT_USE": 0,
                  "ITEM": 1,
                  "ITEM_IMAGE": 2,
                  "PAGE_TILE": 3,
                  "CATEGORY": 4,
                  "ITEM_VARIATION": 5,
                  "FEE": 6,
                  "PLACEHOLDER": 7,
                  "DISCOUNT": 8,
                  "ITEM_FEE_MEMBERSHIP": 9,
                  "ITEM_MODIFIER_LIST": 10,
                  "ITEM_ITEM_MODIFIER_LIST_MEMBERSHIP": 11,
                  "ITEM_MODIFIER_OPTION": 12,
                  "INVENTORY_INFO": 16,
                  "OBSOLETE_TENDER_FEE": 17,
                  "DINING_OPTION": 18,
                  "TAX_RULE": 19,
                  "CONFIGURATION": 20,
                  "PAGE": 21,
                  "TICKET_GROUP": 22,
                  "TICKET_TEMPLATE": 23,
                  "VOID_REASON": 24,
                  "ITEM_VARIATION_VENDOR_INFO": 25,
                  "MENU": 26,
                  "TAG": 27,
                  "FLOOR_PLAN": 28,
                  "FLOOR_PLAN_TILE": 29,
                  "FAVORITES_LIST_POSITION": 30,
                  "MENU_GROUP_MEMBERSHIP": 31,
                  "SURCHARGE": 32,
                  "PRICING_RULE": 33,
                  "PRODUCT_SET": 34,
                  "TIME_PERIOD": 35,
                  "SURCHARGE_FEE_MEMBERSHIP": 36,
                  "MEASUREMENT_UNIT": 37,
                  "SUBSCRIPTION_PLAN_VARIATION": 38,
                  "ITEM_OPTION": 39,
                  "ITEM_OPTION_VAL": 40,
                  "CUSTOM_ATTRIBUTE_DEFINITION": 41,
                  "CUSTOM_ATTRIBUTE_SELECTION": 42,
                  "QUICK_AMOUNTS_SETTINGS": 45,
                  "QUICK_AMOUNT": 46,
                  "COMPONENT": 47,
                  "COMPOSITION": 48,
                  "RESOURCE": 49,
                  "SUBSCRIPTION_PHASE": 50,
                  "CHECKOUT_LINK": 52,
                  "CHECKOUT_LINK_LINE_ITEM": 53,
                  "CHECKOUT_LINK_CUSTOM_FIELD": 54,
                  "SUBSCRIPTION_TAX_INFO": 55,
                  "EVENT": 56,
                  "ADDRESS": 57,
                  "CATALOG_CONFIGURATION": 58,
                  "SUBSCRIPTION_PLAN": 59,
                  "AVAILABILITY_PERIOD": 61,
                  "CREDIT_REDEMPTION_POLICY": 62,
                  "AGE_RESTRICTION": 63,
                  "COMBO_SLOT": 64,
                  "SALE_PRICE": 65,
                  "RECIPE": 66,
                  "INGREDIENT": 67,
                  "TAX_CATEGORY": 68,
                  "COMBO_SLOT_PRICE_ADJUSTMENT": 69,
                  "PRODUCT": 5,
                  "PRODUCT_FAMILY": 1,
                  "CATALOG": 100,
                  "CONSTRAINT": 101,
                  "ATTRIBUTE_DEFINITION_METADATA": 200
                }
              },
              "CatalogObject": {
                "fields": {
                  "token": {
                    "type": "string",
                    "id": 1,
                    "rule": "optional"
                  },
                  "type": {
                    "type": "squareup.items.merchant.CatalogObjectType",
                    "id": 4,
                    "rule": "optional"
                  },
                  "attribute": {
                    "type": "squareup.items.merchant.Attribute",
                    "id": 3,
                    "rule": "repeated"
                  },
                  "version": {
                    "type": "int64",
                    "id": 5,
                    "rule": "optional"
                  },
                  "createdAt": {
                    "type": "int64",
                    "id": 8,
                    "rule": "optional"
                  },
                  "cogsId": {
                    "type": "string",
                    "id": 6,
                    "rule": "optional"
                  },
                  "deleted": {
                    "type": "bool",
                    "id": 7,
                    "rule": "optional"
                  }
                }
              },
              "Attribute": {
                "fields": {
                  "definitionToken": {
                    "type": "string",
                    "id": 2,
                    "rule": "optional"
                  },
                  "unitToken": {
                    "type": "string",
                    "id": 3,
                    "rule": "optional"
                  },
                  "intValue": {
                    "type": "int64",
                    "id": 4,
                    "rule": "optional"
                  },
                  "stringValue": {
                    "type": "string",
                    "id": 5,
                    "rule": "optional"
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// Do not initialize these protos if they have already been defined
if (!$root.nested?.squareup?.nested?.items?.nested?.merchant?.nested?.CatalogObjectType && !$root.nested?.squareup?.nested?.items?.nested?.merchant?.nested?.CatalogObject && !$root.nested?.squareup?.nested?.items?.nested?.merchant?.nested?.Attribute) {
	$root.addJSON(jsonData);
}

/**
 * SOURCE CatalogObjectType @ squareup/merchant/api.proto at 26:1
 */
export const CatalogObjectType = $root.lookupEnum('squareup.items.merchant.CatalogObjectType').values;
/**
 * SOURCE CatalogObject @ squareup/merchant/api.proto at 548:1
 */
export const CatalogObject = $root.lookupType('squareup.items.merchant.CatalogObject');
fixType(CatalogObject);
Builder.createAndAttachToType(CatalogObject);
/**
 * SOURCE Attribute @ squareup/merchant/api.proto at 936:1
 */
export const Attribute = $root.lookupType('squareup.items.merchant.Attribute');
fixType(Attribute);
Builder.createAndAttachToType(Attribute);
