import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import StatusScreen from 'src/components/StatusScreen/StatusScreen';
import { useTranslation } from 'react-i18next';
import './SubscriptionErrorScreen.scss';

/**
 * The status screen to show when SubscriptionStore fails to load. The parent
 * component should be responsible to check SubscriptionStore.status === ERROR.
 *
 * @author klim
 */
const SubscriptionErrorScreen = observer((): ReactElement => {
  const { subscription } = useMessengerControllerContext();
  const { t } = useTranslation();

  return (
    <div
      className="SubscriptionErrorScreen"
      data-testid="SubscriptionErrorScreen"
    >
      <StatusScreen
        action={() =>
          Promise.all([
            subscription.init(),
            subscription.refreshFeatureStatusesOnError(),
          ])
        }
        actionLabel={t('common.retry')}
      >
        {t('common.error.try_again')}
      </StatusScreen>
    </div>
  );
});

export default SubscriptionErrorScreen;
