import { makeAutoObservable, reaction } from 'mobx';
import {
  ContactInfo,
  CouponMetadata,
  MessageDestination,
  ModalType,
  OpenTranscriptEventLoggingSource,
  UnitSetting,
} from 'src/MessengerTypes';
import EventTrackingStore from 'src/stores/EventTrackingStore';
import { MESSAGE_DESTINATION_UNRECOGNIZED } from 'src/utils/transcriptUtils';
import { Contact } from './gen/squareup/messenger/v3/messenger_auxiliary_service';
import { Medium } from './gen/squareup/messenger/v3/messenger_service';

/**
 * Controls the opening and closing of modals.
 */
export default class ModalController {
  /**
   * A list of modals that are active. Only the modal at the top of the stack is rendered.
   * It is unlikely that this stack will contain more than 1 modal, unless in rare cases
   * like having to confirm consent while sending a checkout link.
   */
  private _modalStack: ModalType[] = [];

  /**
   * The destination to request the consent on. Note that this has to be stored here
   * instead of MessengerController as the modal can be opened from different parts
   * of the app, and this destination is required to make the request consent call.
   */
  requestConsentDestination: MessageDestination =
    MESSAGE_DESTINATION_UNRECOGNIZED;

  /**
   * The id of the utterance that triggered the marketing classifier, used to send
   * feedback if the merchant thinks that the utterance is not a marketing message.
   */
  requestConsentUtteranceId: number | undefined;

  /**
   * The function to call when consent is confirmed. This is here because it is tied to
   * both the ConfirmConsentModal and the CheckoutLinkModal states.
   */
  onConfirmConsent: (() => void) | undefined = undefined;

  /**
   * The function to call when the seller chooses not to confirm consent. This is here
   * because it is tied to the ConfirmConsentModal and CheckoutLinkModal states.
   * Note(eblaine): I think it is rare that this will be called, but it's here to
   * avoid a promise hanging forever in MessageInput.
   */
  onRejectConsent: (() => void) | undefined = undefined;

  /**
   * The amount to prefill the checkout link modal, if required. Used mainly when suggesting
   * a payment.
   */
  checkoutLinkAmount: number | undefined;

  /**
   * The unit setting of the review request we want to setup.
   */
  reviewSetupUnitSetting: UnitSetting | undefined;

  /**
   * The contact to select contact method for.
   */
  selectContactMethodContact: Contact | undefined;

  /**
   * The unit token to select a contact method for.
   */
  selectContactMethodUnitToken: string | undefined;

  /**
   * The source that the select contact method modal was opened from.
   */
  selectContactMethodSource: OpenTranscriptEventLoggingSource | undefined;

  /**
   * Callback to be executed when the user finishes selecting a contact method.
   */
  selectContactMethodCallback?: (
    contactToken?: Contact['token'],
    medium?: Medium,
    unitToken?: string,
  ) => void;

  /**
   * The coupon details to send a coupon.
   */
  couponMetadata: CouponMetadata | undefined;

  /**
   * The customer info from the suggestion to prefill the new customer modal with.
   */
  createCustomerSuggestionMetadata?: ContactInfo;

  /**
   * The unit tokens to either submit for number verification or the start of an M+
   * subscription with the MessagesPlusSubscriptionModal
   */
  messagesPlusSubscriptionModalUnitTokens?: string[];

  /**
   * The max total size for all files allowed to be uploaded per message.
   */
  fileSizeMaxLimit?: number;

  /**
   * The amount of file size exceeding over the max limit for uploading.
   */
  fileSizeExceedAmount?: number;

  /**
   * Id of SMS transcript used to look up email contacts when file uploading is not
   * available on SMS.
   */
  fileEmailOnlyTranscriptId?: number;

  /**
   * Callback function when "Continue" is clicked when sending files for the first time.
   */
  fileFirstTimeOnContinue?: () => void;

  /**
   * The copy variant to indicate that files and photos must be sent separately.
   */
  fileAndPhotoMutuallyExclusiveVariant?: 'PHOTO' | 'FILE';

  /**
   * The copy variant for the context that the unverified modal is opened in.
   */
  unverifiedModalVariant?: 'SUBSCRIPTION_BLOCKED' | 'MESSAGE_BLOCKED';

  // Send event tracking whenever we open a modal.
  constructor(tracking: EventTrackingStore) {
    makeAutoObservable(this, {
      onConfirmConsent: false,
      onRejectConsent: false,
      fileFirstTimeOnContinue: false,
    });

    reaction(
      () => this.currentModal,
      (modal) => {
        switch (modal) {
          case 'COUPON':
            tracking.track('View Send Coupon Modal');
            break;
          case 'NEW_CUSTOMER':
            tracking.track('View New Customer Modal');
            break;
          case 'SEND_LIMIT':
            tracking.track('View Send Limit Modal');
            break;
          case 'CHECKOUT_LINK':
            tracking.track('View Send Checkout Link Modal');
            break;
          case 'CHANGE_MEDIUM':
            tracking.track('View Change Medium Modal');
            break;
          case 'CHANGE_LOCATION':
            tracking.track('View Change Location Modal');
            break;
          case 'UNVERIFIED_IDENTITY':
            tracking.track('View IDV Modal');
            break;
          case 'PAYMENT_ALTERNATIVES':
            tracking.track('View Payment Alternatives Modal');
            break;
          default:
        }
      },
    );
  }

  /**
   * The current modal used to decide which one to render.
   */
  get currentModal(): ModalType {
    if (this._modalStack.length > 0) {
      return this._modalStack[this._modalStack.length - 1];
    }
    return 'NONE';
  }

  closeModal = (): void => {
    const lastModal = this._modalStack.pop();
    if (lastModal) {
      switch (lastModal) {
        case 'CONFIRM_CONSENT':
          this.onConfirmConsent = undefined;
          this.onRejectConsent = undefined;
          break;
        case 'REQUEST_CONSENT':
          this.requestConsentDestination = MESSAGE_DESTINATION_UNRECOGNIZED;
          this.requestConsentUtteranceId = undefined;
          break;
        case 'CHECKOUT_LINK':
          this.checkoutLinkAmount = undefined;
          break;
        case 'REVIEW_SETUP':
          this.reviewSetupUnitSetting = undefined;
          break;
        case 'COUPON':
          this.couponMetadata = undefined;
          break;
        case 'CREATE_CUSTOMER_SUGGESTION':
          this.createCustomerSuggestionMetadata = undefined;
          break;
        case 'FILE_LIMIT_REACHED':
          this.fileSizeMaxLimit = undefined;
          this.fileSizeExceedAmount = undefined;
          break;
        case 'FILE_EMAIL_ONLY':
          this.fileEmailOnlyTranscriptId = undefined;
          break;
        default:
      }
    }
  };

  openModal = (name: ModalType): void => {
    this._modalStack.push(name);
  };

  openCouponModal = (metadata?: CouponMetadata): void => {
    this._modalStack.push('COUPON');
    this.couponMetadata = metadata;
  };

  openNewCustomerModal = (): void => {
    this._modalStack.push('NEW_CUSTOMER');
  };

  openCreateCustomerSuggestionModal = (metadata?: ContactInfo): void => {
    this.createCustomerSuggestionMetadata = metadata;
    this.openModal('CREATE_CUSTOMER_SUGGESTION');
  };

  openRequestConsentModal = (
    destination: MessageDestination,
    utteranceId?: number,
  ): void => {
    this._modalStack.push('REQUEST_CONSENT');
    this.requestConsentDestination = destination;
    this.requestConsentUtteranceId = utteranceId;
  };

  openConfirmConsentModal = (
    onConfirmConsent: () => void,
    onRejectConsent: () => void,
  ): void => {
    this._modalStack.push('CONFIRM_CONSENT');
    this.onConfirmConsent = onConfirmConsent;
    this.onRejectConsent = onRejectConsent;
  };

  openSendLimitModal = (): void => {
    this._modalStack.push('SEND_LIMIT');
  };

  openCheckoutLinkModal = (amount?: number): void => {
    this._modalStack.push('CHECKOUT_LINK');
    this.checkoutLinkAmount = amount;
  };

  openPhotosGallery = (): void => {
    this._modalStack.push('PHOTOS_GALLERY');
  };

  openDoNotDisturbModal = (): void => {
    this._modalStack.push('DO_NOT_DISTURB');
  };

  openConfirmBlockModal = (): void => {
    this._modalStack.push('CONFIRM_BLOCK');
  };

  openMessageBlockedModal = (): void => {
    this._modalStack.push('MESSAGE_BLOCKED');
  };

  openUnverifiedModal = (
    variant: 'SUBSCRIPTION_BLOCKED' | 'MESSAGE_BLOCKED',
  ): void => {
    this._modalStack.push('UNVERIFIED_IDENTITY');
    this.unverifiedModalVariant = variant;
  };

  openReviewSetupModal = (unitSetting: UnitSetting): void => {
    this._modalStack.push('REVIEW_SETUP');
    this.reviewSetupUnitSetting = unitSetting;
  };

  openSelectContactMethodModal = (
    contact: Contact,
    source: OpenTranscriptEventLoggingSource,
    unitToken?: string,
    callback?: (
      contactToken?: Contact['token'],
      medium?: Medium,
      unitToken?: string,
    ) => void,
  ): void => {
    this._modalStack.push('SELECT_CONTACT_METHOD');
    this.selectContactMethodContact = contact;
    this.selectContactMethodUnitToken = unitToken;
    this.selectContactMethodCallback = callback;
    this.selectContactMethodSource = source;
  };

  openPaymentAlternativesModal = (): void => {
    this._modalStack.push('PAYMENT_ALTERNATIVES');
  };

  openMessagesPlusSubscriptionModal = (unitTokens: string[]): void => {
    this._modalStack.push('MESSAGES_PLUS_SUBSCRIPTION');
    this.messagesPlusSubscriptionModalUnitTokens = unitTokens;
  };

  openFileLimitReachedModal = (
    fileSizeMaxLimit: number,
    fileSizeExceedAmount: number,
  ): void => {
    this._modalStack.push('FILE_LIMIT_REACHED');
    this.fileSizeMaxLimit = fileSizeMaxLimit;
    this.fileSizeExceedAmount = fileSizeExceedAmount;
  };

  openFileEmailOnlyModal = (transcriptId: number): void => {
    this._modalStack.push('FILE_EMAIL_ONLY');
    this.fileEmailOnlyTranscriptId = transcriptId;
  };

  openFileFirstTimeModal = (onContinue: () => void): void => {
    this._modalStack.push('FILE_FIRST_TIME');
    this.fileFirstTimeOnContinue = onContinue;
  };

  openFileAndPhotoMutuallyExclusiveModal = (
    variant: 'PHOTO' | 'FILE',
  ): void => {
    this._modalStack.push('FILE_PHOTO_MUTUALLY_EXCLUSIVE');
    this.fileAndPhotoMutuallyExclusiveVariant = variant;
  };

  openSquareOneDedicatedNumberFeatureOnlyDialog = (): void => {
    this._modalStack.push('DEDICATED_NUMBER_FEATURE_ONLY_DIALOG');
  };

  openSquareOneUpsellModal = (): void => {
    this._modalStack.push('SQUARE_ONE_UPSELL_MODAL');
  };
}
