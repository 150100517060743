/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../Fixes';
import Builder from '../../Builder';

const $root = ($protobuf.roots['default']|| ($protobuf.roots['default'] = new $protobuf.Root()));

const jsonData = {
  "squareup": {
    "nested": {
      "employees": {
        "nested": {
          "AccessBlocker": {
            "values": {
              "DO_NOT_USE": 0,
              "EMPLOYEE_MANAGEMENT_SUBSCRIPTION": 1,
              "EMPLOYEE_MANAGEMENT_SUBSCRIPTION_PAUSED": 2
            }
          }
        }
      }
    }
  }
}

// Do not initialize these protos if they have already been defined
if (!$root.nested?.squareup?.nested?.employees?.nested?.AccessBlocker) {
	$root.addJSON(jsonData);
}

/**
 * Reasons for an employee's access to POS and Dashboard to be blocked
 *
 * SOURCE AccessBlocker @ squareup/employees/employees.proto at 822:1
 */
export const AccessBlocker = $root.lookupEnum('squareup.employees.AccessBlocker').values;
