import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Feature, UpsellPill } from '@squareup/saas-shared-ui';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import Link from 'src/components/Link/Link';
import './BusinessNumbersTitle.scss';

/**
 * Component that renders the title for the business numbers section of the settings page.
 */
const BusinessNumbersTitle = observer((): ReactElement => {
  const { user, subscription, navigation, modal } =
    useMessengerControllerContext();
  const { t } = useTranslation();
  const { unitsWithPendingOrDedicatedNumbers } = user;

  if (subscription.isEligibleForSquareOne) {
    return (
      <div className="BusinessNumbersTitle__container">
        <div className="BusinessNumbersTitle__upsell-container">
          <h3 className="BusinessNumbersTitle__title">
            {!subscription.isMessagesFeatureEnabledWithSquareOne ||
            unitsWithPendingOrDedicatedNumbers.length === 0
              ? t('SettingsPage.business_numbers.claim_number_title')
              : t('SettingsPage.business_numbers.title', {
                  count: unitsWithPendingOrDedicatedNumbers.length,
                })}
          </h3>
          <UpsellPill feature={Feature.MESSAGES_PLUS} size="small" />
        </div>
        {subscription.showSquareOneMessagesFeatureUpsell && (
          <Link
            onClick={() => {
              modal.openSquareOneUpsellModal();
            }}
          >
            {t('SettingsPage.business_numbers.get_started')}
          </Link>
        )}
        {subscription.isMessagesFeatureEnabledWithSquareOne &&
          unitsWithPendingOrDedicatedNumbers.length === 0 && (
            <Link
              onClick={() => {
                navigation.openStartVerificationView();
              }}
            >
              {t('SettingsPage.business_numbers.claim_number_cta')}
            </Link>
          )}
      </div>
    );
  }

  return (
    <h3>
      {t('SettingsPage.business_numbers.title', {
        count: unitsWithPendingOrDedicatedNumbers.length,
      })}
    </h3>
  );
});

export default BusinessNumbersTitle;
