/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../../Fixes';
import Builder from '../../../Builder';

const $root = ($protobuf.roots['default']|| ($protobuf.roots['default'] = new $protobuf.Root()));

const jsonData = {
  "squareup": {
    "nested": {
      "omg": {
        "nested": {
          "opt": {
            "nested": {
              "OrdersFeature": {
                "values": {
                  "DO_NOT_USE": 0,
                  "TEST": 1,
                  "EXTERNAL_TENDERS": 2,
                  "SHORT_REFERENCE_ID": 5,
                  "READ_ALL_BILLS_AS_ORDERS": 8,
                  "ALLOW_DISCOUNT_CODES": 10,
                  "ALLOW_SELLER_DELIVERY": 12,
                  "ALLOW_DISCOUNT_QUANTITY": 13,
                  "ALLOW_ORDER_WORKFLOW": 14,
                  "ALLOW_MAXIMUM_AMOUNT": 15,
                  "ALLOW_POST_TAX_DISCOUNT": 16,
                  "ALLOW_DISCOUNT_APPLY_PER_QUANTITY": 18,
                  "ALLOW_GIFT_CARDS": 19,
                  "TICKET_NAME": 20,
                  "ALLOW_SKU": 21,
                  "ALLOW_FULFILLMENT_ENTRIES": 23,
                  "ALLOW_COMPS": 24,
                  "ALLOW_VOIDS": 25,
                  "ALLOW_QUANTITY_ENTRY_TYPE": 28,
                  "NOTE": 29,
                  "ALLOW_AUTOMATIC_TAX_RATES": 30,
                  "ALLOW_SEQUENTIAL_NUMBER": 32,
                  "ALLOW_RETURNS": 33,
                  "ALLOW_DIGITAL_DETAILS": 34,
                  "AUTO_APPLY_SERVICE_CHARGES": 35,
                  "ALLOW_SQUARE_DELIVERY_FULFILLMENT": 36,
                  "ALLOW_FULFILLMENT_LOCATION_ID": 39,
                  "ALLOW_DINING_OPTIONS": 40,
                  "ALLOW_UNLINKED_RETURNS": 42,
                  "ALLOW_PROCESSING_MODE": 43,
                  "ALLOW_FULFILLMENT_CREATED_AT": 44,
                  "ALLOW_SIMPLE_FULFILLMENT": 45,
                  "ALLOW_PRECALCULATED_ORDERS": 46,
                  "ALLOW_PARTIAL_PAYMENT": 47,
                  "ALLOW_TAX_EXEMPTION": 48,
                  "ALLOW_DEPOSITS": 49,
                  "ALLOW_PAID_AT": 50,
                  "ALLOW_CLONE_LOCATION_OVERRIDE": 51,
                  "ALLOW_CHANNEL": 52,
                  "ALLOW_API_REFERENCE_IDS": 53,
                  "ALLOW_SUBSCRIPTION_ID_WRITES": 54,
                  "ALLOW_SUBSCRIPTION_ID_READS": 55,
                  "ALLOW_CREDIT_REDEMPTIONS": 56,
                  "ALLOW_ENHANCED_ORDER_ENTRY": 57,
                  "ALLOW_EXCLUDED_FEATURES": 58,
                  "ALLOW_TAX_INCLUSION_TYPE": 59,
                  "ALLOW_COMMITMENT_OWNER": 60,
                  "ALLOW_DINE_IN_FULFILLMENT": 61,
                  "ALLOW_IN_STORE_FULFILLMENT": 68,
                  "ALLOW_FUTURE_STATE": 62,
                  "ENABLE_FUTURE_STATE_VALIDATOR": 63,
                  "ALLOW_FUTURE_STATE_WRITE": 64,
                  "ALLOW_COMBOS": 65,
                  "ALLOW_FLAT_AMOUNT_TAX": 66,
                  "ALLOW_WRITE_TO_CLIENT_TIMESTAMPS": 67,
                  "ALLOW_BOOKING_FULFILLMENT": 69,
                  "ALLOW_CATALOG_CLIENT_TOKEN": 71
                }
              },
              "OrdersFeatures": {
                "fields": {
                  "features": {
                    "type": "squareup.omg.opt.OrdersFeature",
                    "id": 1,
                    "rule": "repeated"
                  },
                  "defaultReadOnly": {
                    "type": "bool",
                    "id": 2,
                    "rule": "optional"
                  },
                  "writeFeatures": {
                    "type": "squareup.omg.opt.OrdersFeature",
                    "id": 3,
                    "rule": "repeated"
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// Do not initialize these protos if they have already been defined
if (!$root.nested?.squareup?.nested?.omg?.nested?.opt?.nested?.OrdersFeature && !$root.nested?.squareup?.nested?.omg?.nested?.opt?.nested?.OrdersFeatures) {
	$root.addJSON(jsonData);
}

/**
 * The list of current OrdersFeatures. See the orders_feature field option for more details.
 *
 * SOURCE OrdersFeature @ squareup/omg/opt/options.proto at 175:1
 */
export const OrdersFeature = $root.lookupEnum('squareup.omg.opt.OrdersFeature').values;
/**
 * SOURCE OrdersFeatures @ squareup/omg/opt/options.proto at 349:1
 */
export const OrdersFeatures = $root.lookupType('squareup.omg.opt.OrdersFeatures');
fixType(OrdersFeatures);
Builder.createAndAttachToType(OrdersFeatures);
