import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import { MarketBanner } from 'src/components/Market';
import { useTranslation } from 'react-i18next';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';

/**
 * A banner to show when a Square One merchant is unable to send messages without
 * TFV first.
 *
 * @author klim
 */
const ClaimYourNumberBanner = observer((): ReactElement => {
  const { t } = useTranslation();
  const { navigation } = useMessengerControllerContext();

  return (
    <MarketBanner variant="info" data-testid="ClaimYourNumberBanner">
      <div className="semibold-30">{t('ClaimYourNumber.title')}</div>
      {t('ClaimYourNumber.description')}
      <button
        slot="action"
        onClick={() => {
          navigation.openStartVerificationView();
        }}
      >
        {t('ClaimYourNumber.button_label')}
      </button>
    </MarketBanner>
  );
});

export default ClaimYourNumberBanner;
