import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { Trans, useTranslation } from 'react-i18next';
import {
  MessengerModalFullHeader,
  MessengerModalFullContent,
} from 'src/components/MessengerModalFull';
import { MarketButton } from 'src/components/Market';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import usePreventAccessIfProhibited from 'src/hooks/usePreventAccessIfProhibited';
import useSavedSubscriptionPaymentMethod from 'src/hooks/useSavedSubscriptionPaymentMethod';
import './UnitVerificationForm.scss';
import BusinessInformationSection from './BusinessInformationSection';
import ContactInformationSection from './ContactInformationSection';
import OnlineBusinessPageSection from './OnlineBusinessPageSection';
import UnsavedModal from 'src/components/UnsavedModal/UnsavedModal';

export type UnitVerificationFormProps = {
  unitToken: string;
};

/**
 * Page that renders the Toll-Free Number Verification form for a unit
 * going through the Messages Plus subscription onboarding (v2).
 *
 * @example
 * Basic usage:
 * <UnitVerificationForm unitToken="abc" />
 * @param {string} unitToken
 * Token for the unit that is registering for the subscription.
 * @author teresalin
 */
const UnitVerificationForm = observer(
  ({ unitToken }: UnitVerificationFormProps): ReactElement => {
    const {
      user,
      navigation,
      status,
      unitVerification,
      modal,
      event,
      subscription,
    } = useMessengerControllerContext();
    const {
      isUnitInformationComplete,
      status: unitsInformationStatus,
      verificationFormHasChanges,
      setShowError,
      unitInformation,
    } = unitVerification;
    const { t } = useTranslation();
    const ref = useRef<HTMLElement>(null);
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const unit = user.units.get(unitToken);

    usePreventAccessIfProhibited();
    useSavedSubscriptionPaymentMethod();

    useEffect(() => {
      // If unit token does not exist in user units, close the sheet
      if (!unit) {
        status.setError({
          label: t('UnitVerificationForm.invalid_unit_error'),
          timerMillis: 3000,
        });
        navigation.closeSheet();
      }
    }, [unit, status, navigation, t]);

    useEffect(() => {
      if (unit && !unit.subscription?.verificationInfoToReview) {
        unitVerification.getUnitsVerificationInformation([unitToken]);
      }
    }, [unit, unitVerification, unitToken]);

    useEffect(() => {
      event.track('View Unit Verification Form', { unit_token: unitToken });
    }, [event, unitToken]);

    const isLoading =
      unitsInformationStatus === 'NOT_STARTED' ||
      unitsInformationStatus === 'LOADING';

    const isSingleUnit =
      navigation.sheet.previousPage?.name !== 'UNITS_TO_VERIFY';

    return (
      <>
        <MessengerModalFullHeader
          navigationVariant={
            subscription.isEligibleForSquareOne &&
            subscription.unitsAvailableForTFNFlow.length === 1
              ? 'CLOSE'
              : 'BACK'
          }
          onNavigateClick={() => {
            if (verificationFormHasChanges) {
              modal.openModal('UNSAVED');
              return;
            }
            navigation.sheet.navigateBack();
          }}
          primaryButton={
            <MarketButton
              size="medium"
              rank="primary"
              isLoading={isSaving || undefined}
              onClick={async () => {
                if (!isUnitInformationComplete) {
                  setShowError(true);
                  return;
                }

                const savedUnitInformation =
                  unit?.subscription?.verificationInfoToReview?.unitInformation;
                const trackingProps = {
                  unit_token: unitToken,
                  business_name:
                    unitInformation.businessName !==
                    savedUnitInformation?.businessName,
                  contact_first_name:
                    unitInformation.contactFirstName !==
                    savedUnitInformation?.contactFirstName,
                  contact_last_name:
                    unitInformation.contactLastName !==
                    savedUnitInformation?.contactLastName,
                  contact_email:
                    unitInformation.contactEmail !==
                    savedUnitInformation?.contactEmail,
                  contact_number:
                    unitInformation.contactPhoneNumber !==
                    savedUnitInformation?.contactPhoneNumber,
                  website:
                    unitInformation.websiteUrl !==
                    savedUnitInformation?.websiteUrl,
                  address:
                    unitInformation.address?.address1 !==
                      savedUnitInformation?.address?.address1 ||
                    unitInformation.address?.address2 !==
                      savedUnitInformation?.address?.address2 ||
                    unitInformation.address?.city !==
                      savedUnitInformation?.address?.city ||
                    unitInformation.address?.state !==
                      savedUnitInformation?.address?.state ||
                    unitInformation.address?.zipCode !==
                      savedUnitInformation?.address?.zipCode,
                  is_successful: true,
                };

                unitVerification.updateLocalUnitInformation();

                if (isSingleUnit) {
                  try {
                    setIsSaving(true);
                    await unitVerification.saveUnitInformationAndNavigate([
                      unitToken,
                    ]);
                    event.track('Submit Unit Verification Form', trackingProps);
                    setIsSaving(false);
                  } catch {
                    setIsSaving(false);
                    event.track('Submit Unit Verification Form', {
                      ...trackingProps,
                      is_successful: false,
                    });
                  }
                  return;
                }

                event.track('Submit Unit Verification Form', trackingProps);
                navigation.sheet.navigateBack();
              }}
              data-testid="UnitVerificationForm__primary-button"
            >
              {isSingleUnit
                ? t('UnitVerificationForm.button_label_next')
                : t('common.done')}
            </MarketButton>
          }
        />
        <MessengerModalFullContent
          ref={ref}
          status={status.value}
          isLoading={isLoading}
        >
          <div>
            <h2 className="UnitVerificationForm__content__header">
              {t('UnitVerificationForm.form_title')}
            </h2>
            <div className="UnitVerificationForm__content__desc">
              <Trans
                i18nKey="UnitsToVerifyPage.description"
                components={{ 1: <strong /> }}
              />
            </div>
            <OnlineBusinessPageSection />
            <BusinessInformationSection />
            <ContactInformationSection />
          </div>
          {modal.currentModal === 'UNSAVED' && (
            <UnsavedModal
              onConfirm={() => {
                modal.closeModal();
                navigation.sheet.navigateBack();
              }}
            />
          )}
        </MessengerModalFullContent>
      </>
    );
  },
);

export default UnitVerificationForm;
