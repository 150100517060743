import React, { ReactElement, useEffect, useRef } from 'react';
import { observer } from 'mobx-react';
import MessengerController from './MessengerController';
import MessengerControllerContext from './context/MessengerControllerContext';
import App from './App';
import { SaaSProvider } from '@squareup/saas-shared-ui';
import { Currency } from './gen/squareup/connect/v2/common/money';
import { getReactRoot } from './utils/shadowDomUtils';

type AppContainerProps = {
  messenger: MessengerController;
};

/**
 * The React component root for the Messages Blade.
 *
 * @param {MessengerController} messenger
 */
const AppContainer = observer(
  ({ messenger }: AppContainerProps): ReactElement => {
    const saasPortalContainerRef = useRef(document.createElement('div'));

    // Adds portal container for Saas modals, which would otherwise render
    // outside the Messages shadow root, which would cause issues with styling
    useEffect(() => {
      const saasPortalContainer = saasPortalContainerRef.current;

      saasPortalContainer.className = 'SaasPortalBlade';
      const rootElement = getReactRoot() ?? document.body;
      rootElement.appendChild(saasPortalContainer);
      return () => {
        rootElement.removeChild(saasPortalContainer);
      };
    }, []);

    return (
      <MessengerControllerContext.Provider value={messenger}>
        <SaaSProvider
          merchantToken={undefined}
          currencyCode={
            (messenger.user.currencyCode as keyof typeof Currency) || 'USD'
          }
          countryCode={messenger.user.countryCode}
          locale={messenger.user.locale}
          name={messenger.user.businessName}
          customModalPortalContainer={saasPortalContainerRef.current}
        >
          <App />
        </SaaSProvider>
      </MessengerControllerContext.Provider>
    );
  },
);

export default AppContainer;
