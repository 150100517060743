import React, { ReactElement, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import { onKeyDownEnter } from 'src/utils/keyboardUtils';
import TooltipCloseIcon from 'src/svgs/TooltipCloseIcon';
import './MessagesPluginLaunchBanner.scss';
import Link from 'src/components/Link/Link';
import { Utterance } from 'src/gen/squareup/messenger/v3/messenger_service';

export type MessagesPluginLaunchBannerProps = {
  source: Utterance.Metadata.MessagesPluginEntry.UrlLabel;
  ftux?: Utterance.Metadata.MessagesPluginEntry.IFtux;
};

/**
 * Banner to show the user when the Messages Plugin is launched. This will show once per
 * surface that the plugin is integrated to, e.g. Appointments BBF and Square Online
 */
const MessagesPluginLaunchBanner = observer(
  ({ source, ftux }: MessagesPluginLaunchBannerProps): ReactElement => {
    const { tooltip, event, navigation, settings } =
      useMessengerControllerContext();
    const { t } = useTranslation();
    const { title, description, imageUrl } = ftux || {};

    useEffect(() => {
      event.track('View Messages Plugin Launch Announcement', {
        source,
      });
    }, [event, source]);

    const onDismiss = (): void => {
      event.track('Click Messages Plugin Launch Announcement Dismiss', {
        source,
      });
      tooltip.dismiss('MESSAGES_PLUGIN_LAUNCH_BANNER');
    };

    return (
      <div
        className="MessagesPluginLaunchBanner__container"
        data-testid="MessagesPluginLaunchBanner"
      >
        <div className="MessagesPluginLaunchBanner__content">
          <div className="MessagesPluginLaunchBanner__title-row">
            <h3 className="MessagesPluginLaunchBanner__title">
              {title || t('MessagesPluginLaunchBanner.title')}
            </h3>
            <div
              className="MessagesPluginLaunchBanner__close"
              data-testid="MessagesPluginLaunchBanner__close"
              onClick={onDismiss}
              onKeyDown={(e) => onKeyDownEnter(e, onDismiss)}
              role="button"
              tabIndex={0}
            >
              <TooltipCloseIcon />
            </div>
          </div>
          <p className="MessagesPluginLaunchBanner__description">
            {description || t('MessagesPluginLaunchBanner.description')}
          </p>
          <img
            src={
              imageUrl ||
              'https://conversations-production-f.squarecdn.com/resources/messages-plugin-ftux.png'
            }
            alt={t('MessagesPluginLaunchBanner.image_alt_text')}
            className="MessagesPluginLaunchBanner__image"
          />
          <div className="MessagesPluginLaunchBanner__actions-row">
            <Link
              onClick={() => {
                event.track('Click Manage Widget', {
                  source,
                });
                tooltip.dismiss('MESSAGES_PLUGIN_LAUNCH_BANNER');
                navigation.openSheet('SETTINGS', 'MESSAGES_PLUGIN');
                if (
                  source ===
                    Utterance.Metadata.MessagesPluginEntry.UrlLabel
                      .SQUARE_ONLINE_SITE &&
                  settings.sqOnlineSettings.length > 1
                ) {
                  navigation.openSheet('SQ_ONLINE_SETTINGS');
                }
              }}
            >
              <span className="MessagesPluginLaunchBanner__text-button">
                {t('MessagesPluginLaunchBanner.action_label')}
              </span>
            </Link>
          </div>
        </div>
      </div>
    );
  },
);

export default MessagesPluginLaunchBanner;
