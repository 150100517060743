/**
 * GENERATED CODE – DO NOT EDIT!
 */
/* eslint-disable */
import * as $protobuf from 'protobufjs/light';
import { fixType } from '../../../Fixes';
import Builder from '../../../Builder';
import '../../paymentreporter/fee';
import '../../connect/v2/common/money';
import '../../connect/v2/resources/address';
import '../../connect/v2/catalog/resources/datatypes';

const $root = ($protobuf.roots['default']|| ($protobuf.roots['default'] = new $protobuf.Root()));

const jsonData = {
  "squareup": {
    "nested": {
      "omg": {
        "nested": {
          "ecom": {
            "nested": {
              "EcomCourierFeeInfo": {
                "fields": {
                  "quoteId": {
                    "type": "string",
                    "id": 1,
                    "rule": "optional"
                  },
                  "courierDeliveryFee": {
                    "type": "squareup.paymentreporter.service.Fee",
                    "id": 2,
                    "rule": "optional"
                  },
                  "courierDeliveryFeeRefund": {
                    "type": "squareup.paymentreporter.service.Fee",
                    "id": 3,
                    "rule": "optional"
                  },
                  "courierDeliveryFeeBuyerFeeAllocation": {
                    "type": "squareup.connect.v2.common.Money",
                    "id": 4,
                    "rule": "optional"
                  },
                  "courierDeliveryFeeSellerFeeAllocation": {
                    "type": "squareup.connect.v2.common.Money",
                    "id": 5,
                    "rule": "optional"
                  },
                  "courierTip": {
                    "type": "squareup.connect.v2.common.Money",
                    "id": 6,
                    "rule": "optional"
                  }
                }
              },
              "EcomDeliveryFilfillmentInfo": {
                "fields": {
                  "uid": {
                    "type": "string",
                    "id": 1,
                    "rule": "optional"
                  },
                  "customerAddressLatitude": {
                    "type": "string",
                    "id": 2,
                    "rule": "optional"
                  },
                  "customerAddressLongitude": {
                    "type": "string",
                    "id": 3,
                    "rule": "optional"
                  },
                  "squareDispatchFee": {
                    "type": "squareup.paymentreporter.service.Fee",
                    "id": 4,
                    "rule": "optional"
                  },
                  "squareDispatchFeeRefund": {
                    "type": "squareup.paymentreporter.service.Fee",
                    "id": 5,
                    "rule": "optional"
                  },
                  "ecomCourierFeeInfo": {
                    "type": "squareup.omg.ecom.EcomCourierFeeInfo",
                    "id": 6,
                    "rule": "optional"
                  }
                }
              },
              "EcomContact": {
                "fields": {
                  "address": {
                    "type": "squareup.connect.v2.resources.Address",
                    "id": 1,
                    "rule": "optional"
                  },
                  "phoneNumber": {
                    "type": "string",
                    "id": 2,
                    "rule": "optional"
                  },
                  "email": {
                    "type": "string",
                    "id": 3,
                    "rule": "optional"
                  },
                  "latitude": {
                    "type": "float",
                    "id": 4,
                    "rule": "optional"
                  },
                  "longitude": {
                    "type": "float",
                    "id": 5,
                    "rule": "optional"
                  },
                  "timeZone": {
                    "type": "string",
                    "id": 6,
                    "rule": "optional"
                  }
                }
              },
              "EcomFulfillmentInfo": {
                "fields": {
                  "pickupAddress": {
                    "type": "squareup.connect.v2.resources.Address",
                    "id": 1,
                    "rule": "optional"
                  },
                  "isKioskOrder": {
                    "type": "bool",
                    "id": 2,
                    "rule": "optional"
                  },
                  "ecomDeliveryFulfillmentInfo": {
                    "type": "squareup.omg.ecom.EcomDeliveryFilfillmentInfo",
                    "id": 3,
                    "rule": "repeated"
                  },
                  "pickupContact": {
                    "type": "squareup.omg.ecom.EcomContact",
                    "id": 4,
                    "rule": "optional"
                  }
                }
              },
              "EcomOrderExtension": {
                "fields": {
                  "storefrontSiteId": {
                    "type": "string",
                    "id": 1,
                    "rule": "optional"
                  },
                  "ecomOrderSource": {
                    "type": "squareup.omg.ecom.EcomOrderExtension.EcomOrderSource",
                    "id": 2,
                    "rule": "optional"
                  },
                  "ecomFulfillmentInfo": {
                    "type": "squareup.omg.ecom.EcomFulfillmentInfo",
                    "id": 3,
                    "rule": "optional"
                  },
                  "catalogSiteId": {
                    "type": "string",
                    "id": 4,
                    "rule": "optional"
                  },
                  "weeblyUserId": {
                    "type": "string",
                    "id": 5,
                    "rule": "optional"
                  },
                  "loyaltyRewardTierId": {
                    "type": "string",
                    "id": 6,
                    "rule": "optional"
                  },
                  "weeblyOrderId": {
                    "type": "string",
                    "id": 7,
                    "rule": "optional"
                  },
                  "ecomDiscountInfo": {
                    "type": "squareup.omg.ecom.EcomDiscountInfo",
                    "id": 8,
                    "rule": "optional"
                  },
                  "isEligibleForTip": {
                    "type": "bool",
                    "id": 9,
                    "rule": "optional"
                  }
                },
                "nested": {
                  "EcomOrderSource": {
                    "values": {
                      "DEFAULT_DO_NOT_USE": 0,
                      "SOURCE_WEB": 1,
                      "SOURCE_DTC": 2,
                      "SOURCE_SITE": 3
                    }
                  }
                }
              },
              "EcomLineItemExtension": {
                "fields": {
                  "isAlcoholic": {
                    "type": "bool",
                    "id": 1,
                    "rule": "optional"
                  },
                  "isPreorder": {
                    "type": "bool",
                    "id": 2,
                    "rule": "optional"
                  },
                  "isOnSale": {
                    "type": "bool",
                    "id": 3,
                    "rule": "optional"
                  },
                  "originalPrice": {
                    "type": "squareup.connect.v2.common.Money",
                    "id": 4,
                    "rule": "optional"
                  },
                  "imageUrl": {
                    "type": "string",
                    "id": 5,
                    "rule": "optional"
                  },
                  "optionChoices": {
                    "type": "string",
                    "id": 6,
                    "rule": "optional"
                  },
                  "itemVariationWeight": {
                    "type": "squareup.connect.v2.merchant_catalog.resources.ItemVariationWeight",
                    "id": 7,
                    "rule": "optional"
                  }
                }
              },
              "EcomServiceChargeExtension": {
                "fields": {
                  "ecomType": {
                    "type": "squareup.omg.ecom.EcomServiceChargeExtension.EcomServiceChargeType",
                    "id": 1,
                    "rule": "optional"
                  }
                },
                "nested": {
                  "EcomServiceChargeType": {
                    "values": {
                      "DEFAULT_DO_NOT_USE": 0,
                      "SHIPPING_RATE_SERVICE_CHARGE": 1,
                      "BUYER_ALLOCATION_DELIVERY_SERVICE_CHARGE": 2,
                      "SELLER_DELIVERY_SERVICE_CHARGE": 3,
                      "CUSTOM_SELLER_SERVICE_CHARGE": 4,
                      "COURIER_TIP_SERVICE_CHARGE": 5
                    }
                  }
                }
              },
              "EcomDiscountInfo": {
                "fields": {
                  "ecomDiscount": {
                    "type": "squareup.omg.ecom.EcomDiscount",
                    "id": 1,
                    "rule": "repeated"
                  }
                }
              },
              "EcomDiscount": {
                "fields": {
                  "uid": {
                    "type": "string",
                    "id": 1,
                    "rule": "optional"
                  },
                  "discountId": {
                    "type": "string",
                    "id": 2,
                    "rule": "optional"
                  },
                  "discountType": {
                    "type": "squareup.omg.ecom.EcomDiscount.DiscountType",
                    "id": 3,
                    "rule": "optional"
                  },
                  "discountCode": {
                    "type": "string",
                    "id": 4,
                    "rule": "optional"
                  },
                  "fulfillmentServiceChargeBeforeDiscount": {
                    "type": "squareup.connect.v2.common.Money",
                    "id": 5,
                    "rule": "optional"
                  },
                  "minimumOrderAmountForDiscount": {
                    "type": "squareup.connect.v2.common.Money",
                    "id": 6,
                    "rule": "optional"
                  }
                },
                "nested": {
                  "DiscountType": {
                    "values": {
                      "DEFAULT_DO_NOT_USE": 0,
                      "NON_FULFILLMENT": 1,
                      "FREE_SHIPPING": 2,
                      "FREE_DELIVERY": 3
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// Do not initialize these protos if they have already been defined
if (!$root.nested?.squareup?.nested?.omg?.nested?.ecom?.nested?.EcomCourierFeeInfo && !$root.nested?.squareup?.nested?.omg?.nested?.ecom?.nested?.EcomDeliveryFilfillmentInfo && !$root.nested?.squareup?.nested?.omg?.nested?.ecom?.nested?.EcomContact && !$root.nested?.squareup?.nested?.omg?.nested?.ecom?.nested?.EcomFulfillmentInfo && !$root.nested?.squareup?.nested?.omg?.nested?.ecom?.nested?.EcomOrderExtension && !$root.nested?.squareup?.nested?.omg?.nested?.ecom?.nested?.EcomOrderExtension?.nested?.EcomOrderSource && !$root.nested?.squareup?.nested?.omg?.nested?.ecom?.nested?.EcomLineItemExtension && !$root.nested?.squareup?.nested?.omg?.nested?.ecom?.nested?.EcomServiceChargeExtension && !$root.nested?.squareup?.nested?.omg?.nested?.ecom?.nested?.EcomServiceChargeExtension?.nested?.EcomServiceChargeType && !$root.nested?.squareup?.nested?.omg?.nested?.ecom?.nested?.EcomDiscountInfo && !$root.nested?.squareup?.nested?.omg?.nested?.ecom?.nested?.EcomDiscount && !$root.nested?.squareup?.nested?.omg?.nested?.ecom?.nested?.EcomDiscount?.nested?.DiscountType) {
	$root.addJSON(jsonData);
}

/**
 * Courier fee breakdown for orders with delivery fulfillment.
 *
 * SOURCE EcomCourierFeeInfo @ squareup/omg/ecom/extensions.proto at 17:1
 */
export const EcomCourierFeeInfo = $root.lookupType('squareup.omg.ecom.EcomCourierFeeInfo');
fixType(EcomCourierFeeInfo);
Builder.createAndAttachToType(EcomCourierFeeInfo);
/**
 * Information specific to delivery fulfillment for Square Online orders.
 *
 * SOURCE EcomDeliveryFilfillmentInfo @ squareup/omg/ecom/extensions.proto at 38:1
 */
export const EcomDeliveryFilfillmentInfo = $root.lookupType('squareup.omg.ecom.EcomDeliveryFilfillmentInfo');
fixType(EcomDeliveryFilfillmentInfo);
Builder.createAndAttachToType(EcomDeliveryFilfillmentInfo);
/**
 * SOURCE EcomContact @ squareup/omg/ecom/extensions.proto at 57:1
 */
export const EcomContact = $root.lookupType('squareup.omg.ecom.EcomContact');
fixType(EcomContact);
Builder.createAndAttachToType(EcomContact);
/**
 * Fulfillment information specific to Square Online orders.
 *
 * SOURCE EcomFulfillmentInfo @ squareup/omg/ecom/extensions.proto at 78:1
 */
export const EcomFulfillmentInfo = $root.lookupType('squareup.omg.ecom.EcomFulfillmentInfo');
fixType(EcomFulfillmentInfo);
Builder.createAndAttachToType(EcomFulfillmentInfo);
/**
 * Order-level extension. Information specific to Square Online orders.
 *
 * SOURCE EcomOrderExtension @ squareup/omg/ecom/extensions.proto at 93:1
 */
export const EcomOrderExtension = $root.lookupType('squareup.omg.ecom.EcomOrderExtension');
fixType(EcomOrderExtension);
Builder.createAndAttachToType(EcomOrderExtension);
/**
 * Square Online checkout source.
 *
 * SOURCE EcomOrderSource @ squareup/omg/ecom/extensions.proto at 95:3
 */
EcomOrderExtension.EcomOrderSource = $root.lookupEnum('squareup.omg.ecom.EcomOrderExtension.EcomOrderSource').values;
/**
 * LineItem-level extension. Information specific to Square Online orders.
 *
 * SOURCE EcomLineItemExtension @ squareup/omg/ecom/extensions.proto at 131:1
 */
export const EcomLineItemExtension = $root.lookupType('squareup.omg.ecom.EcomLineItemExtension');
fixType(EcomLineItemExtension);
Builder.createAndAttachToType(EcomLineItemExtension);
/**
 * ServiceCharge-level extension. Information specific to Square Online orders.
 *
 * SOURCE EcomServiceChargeExtension @ squareup/omg/ecom/extensions.proto at 157:1
 */
export const EcomServiceChargeExtension = $root.lookupType('squareup.omg.ecom.EcomServiceChargeExtension');
fixType(EcomServiceChargeExtension);
Builder.createAndAttachToType(EcomServiceChargeExtension);
/**
 * Square Online Service Charge type.
 *
 * SOURCE EcomServiceChargeType @ squareup/omg/ecom/extensions.proto at 159:3
 */
EcomServiceChargeExtension.EcomServiceChargeType = $root.lookupEnum('squareup.omg.ecom.EcomServiceChargeExtension.EcomServiceChargeType').values;
/**
 * Discount-related information specific to Square Online orders.
 *
 * SOURCE EcomDiscountInfo @ squareup/omg/ecom/extensions.proto at 173:1
 */
export const EcomDiscountInfo = $root.lookupType('squareup.omg.ecom.EcomDiscountInfo');
fixType(EcomDiscountInfo);
Builder.createAndAttachToType(EcomDiscountInfo);
/**
 * Discount breakdown for each coupon on the order
 *
 * SOURCE EcomDiscount @ squareup/omg/ecom/extensions.proto at 178:1
 */
export const EcomDiscount = $root.lookupType('squareup.omg.ecom.EcomDiscount');
fixType(EcomDiscount);
Builder.createAndAttachToType(EcomDiscount);
/**
 * SOURCE DiscountType @ squareup/omg/ecom/extensions.proto at 179:3
 */
EcomDiscount.DiscountType = $root.lookupEnum('squareup.omg.ecom.EcomDiscount.DiscountType').values;
